import { Box, Fab, Tooltip } from '@mui/material'
import React from 'react'

import style from './GenericTooltip.module.css'
import { GenericToolTipListExpressions } from './GenericToolTipListExpressions'

export type GenericTooltipProps = {
  values: string[]
  icon: JSX.Element
  noUseFab?: boolean
}

export function GenericTooltip(props: GenericTooltipProps): JSX.Element {
  return (
    <Tooltip
      classes={{
        tooltip: style.genericTooltip,
      }}
      title={<GenericToolTipListExpressions values={props.values} />}
      enterTouchDelay={0}
    >
      <Box>
        {props.noUseFab ? (
          <div>{props.icon}</div>
        ) : (
          <Fab color="primary" aria-label="info">
            {props.icon}
          </Fab>
        )}
      </Box>
    </Tooltip>
  )
}
