import { Statistics } from './Statistics'
import { v4 as uuidv4 } from 'uuid'

export interface StatisticsDTO {
  id: string
  value: number
  userId: string
  date: Date
}

export function emptyStatisticsDTO(): StatisticsDTO {
  return {
    id: uuidv4(),
    value: 0,
    userId: '',
    date: new Date(),
  }
}

export function fromModel(t: Statistics): StatisticsDTO {
  return {
    id: t.id,
    value: t.value,
    userId: t.userId,
    date: t.date,
  }
}

export function toModel(d: StatisticsDTO): Statistics {
  return new Statistics(d)
}
