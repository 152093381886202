import { Article } from './Article'
import { v4 as uuidv4 } from 'uuid'

export interface ArticleDTO {
  id: string | undefined
  title: string
  content: string
  createdAt: Date
  updatedAt: Date
  creatorID: string
}

export function emptyArticleDTO(creatorID: string): ArticleDTO {
  return {
    id: uuidv4(),
    title: '',
    content: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    creatorID: creatorID,
  }
}

export function fromModel(a: Article): ArticleDTO {
  return {
    id: a.id,
    title: a.title,
    content: a.content,
    createdAt: a.createdAt,
    updatedAt: a.updatedAt,
    creatorID: a.creatorID,
  }
}

export function toModel(d: ArticleDTO): Article {
  return new Article(d)
}
