import { Container } from '../../common/container/Container'

export type AlgorithmExerciseContainerConfig = {
  moduleFullUrl: string
}

export type AlgorithmExerciseProps = {
  parentContainer: Container
  config: AlgorithmExerciseContainerConfig
}

export const EXERCISEALGORITHM_MODULE = Symbol('EXERCISEALGORITHM_MODULE')
export const EXERCISEALGORITHM_API_KEY = Symbol('EXERCISEALGORITHM_API_KEY')
export const EXERCISEALGORITHM_SERVICE_KEY = Symbol('EXERCISEALGORITHM_SERVICE_KEY')
