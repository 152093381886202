import { Message } from './Message'
import { Conversation } from './Conversation'
import { Reaction } from './Reaction'
import { ReadingStatus } from '../enums/ReadingStatus'
import { MessageUser } from './MessageUser'
import { v4 as uuidv4 } from 'uuid'
import { ConversationStatus } from '../enums/ConversationStatus'

export interface ConversationDTO {
  id: string | undefined
  userID: string
  doctorID: string
  name: string
  messages: MessageDTO[] | undefined
  createdAt: Date
  updatedAt: Date
  status: ConversationStatus
  createdBy: string
}

export function emptyConversationDTO(idCreator: string): ConversationDTO {
  const id = uuidv4()
  return {
    id,
    userID: '',
    doctorID: '',
    name: '',
    messages: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    status: ConversationStatus.New,
    createdBy: idCreator,
  }
}

export function toModel(d: ConversationDTO): Conversation {
  return new Conversation({
    id: d.id,
    name: d.name,
    userID: d.userID,
    doctorID: d.doctorID,
    messages: d.messages?.map((m) => messageToModel(m)),
    createdAt: d.createdAt,
    updatedAt: d.updatedAt,
    status: d.status,
    createdBy: d.createdBy,
  })
}

export function fromModel(c: Conversation): ConversationDTO {
  return {
    id: c.id,
    name: c.name,
    userID: c.userID,
    doctorID: c.doctorID,
    messages: c.messages,
    createdAt: c.createdAt,
    updatedAt: c.updatedAt,
    status: c.status,
    createdBy: c.createdBy,
  }
}

export interface MessageDTO {
  id?: string | undefined
  text: string
  authorID: string
  conversationID: string
  createdAt: Date
  files?: string[]
  senderID: string
  reactions?: ReactionDTO[]
}

export function messageToModel(m: MessageDTO): Message {
  return new Message({
    id: m.id,
    text: m.text,
    authorID: m.authorID,
    conversationID: m.conversationID,
    createdAt: m.createdAt,
    files: m.files,
    senderID: m.senderID,
    reactions: m.reactions?.map((m) => reactionToModel(m)) || [],
  })
}

export function messageFromModel(m: Message): MessageDTO {
  return {
    id: m.id,
    text: m.text,
    authorID: m.authorID,
    conversationID: m.conversationID,
    createdAt: m.createdAt,
    files: m.files,
    senderID: m.senderID,
    reactions: m.reactions?.map((r) => reactionFromModel(r)),
  }
}

export interface Message2UsersDTO {
  userID: string
  messageID: string
  readingStatus: ReadingStatus
}

export interface ReactionDTO {
  userID: string
  messageID: string
  emoji: string
}

export function reactionFromModel(r: Reaction): ReactionDTO {
  return {
    emoji: r.emoji,
    messageID: r.messageID,
    userID: r.userID,
  }
}

export function reactionToModel(r: ReactionDTO): Reaction {
  return new Reaction(r)
}

export function message2UsersToModel(m: Message2UsersDTO): MessageUser {
  return new MessageUser(m)
}
