import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'

export const Input = styled(TextField)({
  '& .MuiFormControl-root': {
    width: '350px',
    height: 35,
    border: 'none',
  },
  '& .MuiInputBase-root': {
    height: '40px',
    border: 'none',
    background: '#F9F9F9',
    textAlign: 'center',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    textTransform: 'none',
    ' & fieldset': {
      border: '1px solid #E8E7EC',
      borderRadius: 32,
    },
    '& input': {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px #fff inset',
        WebkitTextFillColor: 'default',
      },
      '& input:-internal-autofill-selected': {
        backgroundColor: 'transparent !important',
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    border: 'none',
    '&.Mui-focused fieldset': {
      borderColor: '#2E7161',
    },
  },
  '&:hover': {
    background: '#F9F9F9',
    transform: 'scale(1.01)',
    transition: 'all 0.2s ease-in-out',
    borderColor: '#2E7161',
  },
})

export const InputTextDescription = styled(TextField)({
  '& .MuiFormControl-root': {
    width: '850px',
    height: '150px',
  },
  '& .MuiInputBase-root': {
    height: '150px',
    borderRadius: 32,
    background: '#F9F9F9',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    textTransform: 'none',
    padding: '14px 20px 15px 20px',
    ' & fieldset': {
      border: '1px solid #E8E7EC',
      borderRadius: 32,
    },
    '& input': {
      height: '100%',
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px #fff inset',
        WebkitTextFillColor: 'default',
      },
      '& input:-internal-autofill-selected': {
        backgroundColor: 'transparent !important',
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    height: '100%',
    padding: '14px 20px 15px 20px',
    '&.Mui-focused fieldset': {
      borderColor: '#2E7161',
    },
  },
  '&:hover': {
    background: '#F9F9F9',
    transform: 'scale(1.01)',
    transition: 'all 0.2s ease-in-out',
  },
})
