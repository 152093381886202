import React from 'react'
import { Card } from 'react-bootstrap'

export const Messages = ({ message, photo, name }) => {
  let msgClass = []
  if (message.type) {
    msgClass = [...msgClass, 'chat-menu-content']
  } else {
    msgClass = [...msgClass, 'chat-menu-reply text-muted']
  }

  return (
    <React.Fragment>
      <Card className="media chat-messages">
        <Card.Body className={msgClass.join(' ')}>
          <div className="">
            <p className="chat-cont">{message.msg}</p>
          </div>
          <p className="chat-time">{message.time}</p>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}
