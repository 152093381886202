import { Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager } from '../../components/table/types'
import editIcon from '../../assets/articles/edit.svg'
import deleteIcon from '../../assets/articles/delete.svg'
import { useTranslation } from 'react-i18next'
import { getContentContainer } from '../../container/treatment-module'
import {
  ITreatmentService,
  TREATMENT_SERVICE_KEY,
  USER_TREATMENT_SERVICE_KEY,
} from '../../modules/treatments'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { Query, QueryParam } from '../../common/api/Query'
import { ROUTE_TREATMENTS } from '../../routes/routes-constants'
import { CustomModal } from '../../components/modal/CustomModal'
import { Box, Modal } from '@mui/material'
import { Table as TableTreatment } from '@mui/material'
import { TableContainer } from '@mui/material'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { HeadTreatment } from '../../components/table/HeadTreatment'
import { BodyTreatment } from '../../components/table/BodyTreatment'
import { useNavigate } from 'react-router-dom'
import { IUserTreatmentService } from '../../modules/treatments/services/UserTreatmentService'
import { UserTreatmentWithTreatmentName } from '../../modules/treatments/models/UserTreatmentWithTreatmentName'
import { UserTreatmentQuery } from '../../modules/treatments/models/UserTreatment'

type TreatmentTableProps = {
  id?: string
}

interface UserTreatmentTable {
  id: string
  name: string
  creatorID: string
}

const userContainer = getUserContainer()
const treatmentService = getContentContainer().get<ITreatmentService>(TREATMENT_SERVICE_KEY)
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const userTreatmentService = getContentContainer().get<IUserTreatmentService>(
  USER_TREATMENT_SERVICE_KEY
)

export function Table(props: TreatmentTableProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  const [treatments, setTreatments] = useState<UserTreatmentWithTreatmentName[]>([])
  const [treatmentsFiltered, setTreatmentsFiltered] = useState<UserTreatmentTable[]>([])
  const [treatmentsPerPage, setTreatmentsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [users, setUsers] = useState<Map<string, string>>(new Map())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [currentTreatment, setCurrentTreatment] = useState<UserTreatmentTable>()
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const innerWidth = window.innerWidth

  useEffect(() => {
    if (!isLoading || !props.id) {
      return
    }
    userTreatmentService
      .getListWithTreatmentNameByUserID(
        new Query({
          query: [new QueryParam<UserTreatmentQuery>('userID', props.id)],
          pager: { limit: treatmentsPerPage, offset: page * treatmentsPerPage },
        })
      )
      .subscribe((res) => {
        const newTreatments: UserTreatmentTable[] = []
        res.items.forEach((t) => {
          newTreatments.push({
            id: t.userTreatment.id ?? '',
            name: t.name,
            creatorID: t.userTreatment.creatorID ?? '',
          })
        })
        setTreatmentsFiltered(newTreatments)
        setTreatments(res.items)
        setIsLoading(false)
      })
  }, [isLoading, props.id])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setTreatmentsPerPage(10)
      return
    }
    setTreatmentsPerPage(Number.parseInt(event.target.value))
  }
  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: treatmentsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, treatmentsPerPage])

  const editTreatment = (t: UserTreatmentTable) => {
    setCurrentTreatment(t)
    navigate(`${ROUTE_TREATMENTS}/${t.id}`)
  }

  const removeTreatment = (t: UserTreatmentTable) => {
    setCurrentTreatment(t)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => setOpenDeleteModal(false)

  const handleDeleteTreatment = () => {
    if (currentTreatment?.id)
      userTreatmentService.delete(currentTreatment.id).subscribe((_) => setIsLoading(true))
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  const isCreator = (t: UserTreatmentTable): boolean => t.creatorID === loggedUser?.id

  const fields: Field<UserTreatmentTable>[] = [
    {
      name: 'name',
      label: t('name'),
    },
  ]

  const actions: Actions<UserTreatmentTable> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editTreatment,
        icon: editIcon,
        label: 'edit',
        hidden: (t) => !isCreator(t),
      },
      {
        handler: removeTreatment,
        icon: deleteIcon,
        label: 'delete',
        hidden: (t) => !isCreator(t),
      },
    ],
  }

  return (
    <Box>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          title={t('deleteTreatment')}
          warningText={t('irreversibleTreatmentAction')}
          handleClose={handleCloseDeleteModal}
          handleSave={handleDeleteTreatment}
        />
      </Modal>
      <TableContainer
        sx={{
          overflowX: openMenuLeft && innerWidth > 1500 ? 'hidden' : 'auto',
        }}
      >
        <TableTreatment
          sx={{
            border: 'none',
            background: 'transparent',
            margin: 0,
            '& td': {
              border: 'none',
              '&:last-child': {
                border: 'none',
              },
              '&:first-child': {
                borderLeftWidth: 1,
                borderLeft: 'none',
                borderColor: '#2E7161',
                color: '#2E7161',
                fontFamily: 'Montserrat-SemiBold, sans-serif',
              },
            },
            '& tr': {
              border: 'none',
            },
            '& th': {
              border: 'none',
            },
          }}
          style={{ border: 'none' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <HeadTreatment fields={fields} actions={actions} />
            <BodyTreatment
              actions={actions}
              fields={fields}
              items={treatmentsFiltered}
              rowKeyField={'id'}
              isLoading={isLoading}
            />
          </div>
        </TableTreatment>
      </TableContainer>
      {!isLoading && treatments.length <= 0 && (
        <Box>
          <h4>{t('withoutAssignedMedication')}</h4>
        </Box>
      )}
    </Box>
  )
}
