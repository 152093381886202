import { Query } from '../../../common/api/Query'
import { FileDTO } from './FileDTO'

export class File {
  private readonly _id: string | undefined
  private readonly _data: string
  private readonly _mimeType: string
  private readonly _size: number
  private readonly _ownerID: string
  private readonly _createdAt: Date

  private _name: string
  private _description: string
  private _extension: string

  constructor(p: FileDTO) {
    this._id = p.id
    this._data = p.data
    this._mimeType = p.mimeType
    this._extension = p.extension
    this._name = p.name
    this._description = p.description
    this._size = p.size
    this._createdAt = p.createdAt
    this._ownerID = p.ownerID
  }

  get id(): string | undefined {
    return this._id
  }

  get data(): string {
    return this._data
  }

  get mimeType(): string {
    return this._mimeType
  }

  get size(): number {
    return this._size
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get ownerID(): string {
    return this._ownerID
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }

  get extension(): string {
    return this._extension
  }

  set extension(value: string) {
    this._extension = value
  }
}

export class FileQuery extends Query<File> {}
