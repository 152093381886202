import { Container, IInit } from '../../../common/container/Container'
import { Conversation, ConversationQuery } from '../models/Conversation'
import { MessengerContainerConfig } from '../container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import {
  ConversationDTO,
  fromModel,
  MessageDTO,
  messageFromModel,
  messageToModel,
  toModel,
} from '../models/ConversationDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { Message } from '../models/Message'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface IConversationApi extends IInit {
  getByID(id: string): Observable<Conversation | undefined>

  getFilteredItems(q: Query<ConversationQuery>): Observable<Conversation[]>

  add(e: Conversation): Observable<Conversation | undefined>

  addMessage(m: Message): Observable<Message | undefined>

  update(e: Conversation): Observable<Conversation | undefined>

  delete(id: string): Observable<boolean>
}

export class ConversationApi implements IConversationApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as MessengerContainerConfig).moduleFullUrl + '/conversations'
  }

  add(e: Conversation): Observable<Conversation | undefined> {
    return this._httpClient
      .post<Conversation>({ url: this._url, body: fromModel(e) })
      .pipe(
        map<ConversationDTO, Conversation>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  addMessage(m: Message): Observable<Message | undefined> {
    return this._httpClient
      .post<Message>({
        url: `${(this._container.config as MessengerContainerConfig).moduleFullUrl}/messages`,
        body: messageFromModel(m),
      })
      .pipe(
        map<MessageDTO, Message>((d) => messageToModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Conversation | undefined> {
    return this._httpClient
      .get<Conversation>({ url: `${this._url}/${id}` })
      .pipe(
        map<ConversationDTO, Conversation>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  /**
   *   getFilteredItems(q: Query<Conversation>): Observable<ItemList<Conversation>> {
    return this._httpClient
      .get<ItemList<Conversation>>({ url: prepareURL(this._url, q) })
      .pipe(
        map<ItemList<ConversationDTO>, ItemList<Conversation>>((dto) => dto.map((d) => 
        {
          const itemList = emptyList<Conversation>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }
        )),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Conversation>())
        })
      )
  } 
   */

  getFilteredItems(q: Query<ConversationQuery>): Observable<Conversation[]> {
    return this._httpClient
      .get<Conversation[]>({ url: prepareURL(this._url, q) })
      .pipe(
        map<ConversationDTO[], Conversation[]>((dto) => dto.map((d) => toModel(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  update(e: Conversation): Observable<Conversation | undefined> {
    return this._httpClient
      .put<Conversation>({ url: this._url, body: fromModel(e) })
      .pipe(
        map<ConversationDTO, Conversation>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
