import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  CALENDAR_MODULE,
  CalendarProps,
  EVENT_API_KEY,
  EVENT_SERVICE_KEY,
  APPOINTMENT_API_KEY,
  APPOINTMENT_SERVICE_KEY,
  NOTE_API_KEY,
  NOTE_SERVICE_KEY,
  TASK_API_KEY,
  TASK_SERVICE_KEY,
} from '../modules/calendar/container'
import { EventService } from '../modules/calendar/services/EventService'
import { EventApi } from '../modules/calendar/api/EventApi'
import { AppointmentApi } from '../modules/calendar/api/AppointmentApi'
import { NoteApi } from '../modules/calendar/api/NoteApi'
import { TaskApi } from '../modules/calendar/api/TaskApi'

let container: Container

function init(p: CalendarProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(EVENT_API_KEY, new ContainerItem({ instance: new EventApi() }))

  items.set(
    EVENT_SERVICE_KEY,
    new ContainerItem({ instance: new EventService({ apiKey: EVENT_API_KEY }) })
  )

  items.set(APPOINTMENT_API_KEY, new ContainerItem({ instance: new AppointmentApi() }))

  items.set(
    APPOINTMENT_SERVICE_KEY,
    new ContainerItem({ instance: new EventService({ apiKey: APPOINTMENT_API_KEY }) })
  )

  items.set(NOTE_API_KEY, new ContainerItem({ instance: new NoteApi() }))

  items.set(
    NOTE_SERVICE_KEY,
    new ContainerItem({ instance: new EventService({ apiKey: NOTE_API_KEY }) })
  )

  items.set(TASK_API_KEY, new ContainerItem({ instance: new TaskApi() }))

  items.set(
    TASK_SERVICE_KEY,
    new ContainerItem({ instance: new EventService({ apiKey: TASK_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getCalendarContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[CALENDAR_MODULE],
      },
    })
  }

  return container
}
