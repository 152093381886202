import { TrainingDTO } from './TrainingDTO'

export class Training {
  private _id: string
  private _title: string
  private _description: string
  private _image: string

  constructor(p: TrainingDTO) {
    this._id = p.id
    this._title = p.title
    this._description = p.description
    this._image = p.image
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get title(): string {
    return this._title
  }

  set title(value: string) {
    this._title = value
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }

  get image(): string {
    return this._image
  }

  set image(value: string) {
    this._image = value
  }
}
