import { Message } from './Message'
import { Query } from '../../../common/api/Query'
import { DeliveryStatus } from '../enums/DeliveryStatus'
import { TransportType } from '../../../common/enums/TransportType'
import { EventType } from '../enums/EventType'

export type NotificationParams = {
  id?: string
  title: string
  message: string
  recipientID: string
  senderID: string
  sendingStatus: DeliveryStatus
  createdAt: Date
  notType: EventType
  transportType: TransportType
}

export class Notification {
  private readonly _id: string | undefined
  private readonly _title: string
  private readonly _message: string
  private readonly _recipientID: string
  private readonly _senderID: string
  private _sendingStatus: DeliveryStatus
  private readonly _createdAt: Date
  private readonly _transportType: TransportType
  private readonly _notType: EventType

  constructor(p: NotificationParams) {
    this._id = p.id
    this._title = p.title
    this._message = p.message
    this._recipientID = p.recipientID
    this._senderID = p.senderID
    this._sendingStatus = p.sendingStatus
    this._createdAt = new Date()
    this._transportType = p.transportType
    this._notType = p.notType
  }

  get id(): string | undefined {
    return this._id
  }

  get title(): string {
    return this._title
  }

  get message(): string {
    return this._message
  }

  get recipientID(): string {
    return this._recipientID
  }

  get senderID(): string {
    return this._senderID
  }

  get sendingStatus(): DeliveryStatus {
    return this._sendingStatus
  }

  set sendingStatus(value: DeliveryStatus) {
    this._sendingStatus = value
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get transportType(): TransportType {
    return this._transportType
  }

  get notType(): EventType {
    return this._notType
  }
}

export class NotificationQuery extends Query<Message> {}
