import { Box } from '@mui/material'
import { Header } from '../header/Header'
import style from './CustomModal.module.css'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../app-button/AppButton'
import close from '../../assets/articles/delete.svg'

type toAgoraProps = {
  title: string
  warningText: string
  handleClose: () => void
  handleSave: () => void
}

export const CustomModal = (props: toAgoraProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div>
      <div className={style.agoraContainer}>
        <Box className={style.containerInside}>
          <Header label={props.title} icon={close} onClick={props.handleClose} />
          <form>
            <Box mb={3}>
              <p style={{ textAlign: 'center' }}>{props.warningText}</p>
            </Box>
            <Box mt={1} mb={1} className={style.buttons}>
              <Box mr={2}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('submit')}
                  handler={props.handleSave}
                />
              </Box>
              <AppButton
                theme={ButtonTheme.NewSecondary}
                type={'button'}
                label={t('cancel')}
                handler={props.handleClose}
              />
            </Box>
          </form>
        </Box>
      </div>
    </div>
  )
}
