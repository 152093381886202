import { RouteProps } from '../../routes/AppRouter'
import { FormGeneratorTable as F } from '../../features/form-generator'
import React from 'react'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'

export function FormGeneratorTable(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)

  return (
    <>
      <F />
    </>
  )
}
