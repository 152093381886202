import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { AssignTo as TF } from '../../features/form-generator/AssignTo'
import { ROUTE_CREATE } from '../../routes/routes-constants'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import { useParams } from 'react-router-dom'

export function FormGeneratorAssignTo(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()
  const { id } = useParams()

  setTitle(title)

  return (
    <>
      <TF id={id === ROUTE_CREATE ? undefined : id} />
    </>
  )
}
