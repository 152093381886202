export enum SourceType {
  CalendarEventCreated = 1,
  CalendarEventUpdated = 2,
  CalendarNotification = 10,
  MessengerMessageCreated = 51,
  FormsNewUserForm = 101,
  FormsUserFormFilled = 102,
  FilesNewCreated = 301,
  ContentNewArticle = 401,
}

export const sourceTypes = (): Record<SourceType, string> => ({
  [SourceType.CalendarEventCreated]: 'New Event',
  [SourceType.CalendarEventUpdated]: 'Event Updated',
  [SourceType.CalendarNotification]: 'Event Notification',
  [SourceType.MessengerMessageCreated]: 'New Message',
  [SourceType.FormsNewUserForm]: 'Form Pending',
  [SourceType.FormsUserFormFilled]: 'Form Filled',
  [SourceType.FilesNewCreated]: 'A new resource was created',
  [SourceType.ContentNewArticle]: 'A new article was created',
})
