import React from 'react'
import { ViewProps } from './types'

export function DayView(props: ViewProps) {
  return (
    <>
      <div>DayView</div>
    </>
  )
}
