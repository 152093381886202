export type NoteProps = {
  id: string | undefined
  description: string
  date: Date
  userID: string
}

export class Note {
  private readonly _id: string | undefined
  private readonly _description: string
  private readonly _date: Date
  private readonly _userID: string

  constructor(p: NoteProps) {
    this._id = p.id
    this._description = p.description
    this._date = p.date
    this._userID = p.userID
  }

  get id(): string | undefined {
    return this._id
  }

  get description(): string {
    return this._description
  }

  get date(): Date {
    return this._date
  }

  get userID(): string {
    return this._userID
  }
}

export interface NoteQuery {}
