import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { Toolbar, Typography, IconButton, Box } from '@mui/material'
import NavRight from '../header-component/NavRight'
import menu from '../../assets/top-menu/dotsMenu.svg'
import style from './Header.module.css'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import { useNavigate } from 'react-router-dom'

type CustomAppBarProps = {
  open: boolean
  toggleDrawer: () => void
  onChange?: () => void
  title?: string
}

const drawerWidth: number = 310

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: '#474747',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  height: '75px',
  boxShadow: 'none',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  /* ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),*/
}))

const CustomAppBar = (props: CustomAppBarProps) => {
  const { title } = useTitleHeaderContext()

  return (
    <>
      <AppBar open={props.open}>
        <Toolbar>
          <IconButton
            className={style.iconButton}
            edge="start"
            aria-label="open drawer"
            onClick={props.toggleDrawer}
            /*sx={{
              ...(props.open && { display: 'none' }),
            }}*/
          >
            <img src={menu} alt="menu hamburguesa" className={style.iconMenu} />
          </IconButton>
          <Box className={style.headerContainer}>
            <Typography component="h1" variant="h6" noWrap className={style.title}>
              {title}
            </Typography>
            <NavRight onChange={props.onChange} />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default CustomAppBar
