import { v4 as uuidv4 } from 'uuid'
import { Task } from './Task'

export interface TaskDTO {
  id: string | undefined
  title: string
  description: string
  idObject: string
  date: Date
  category: number
  userID: string
}

export function emptyTaskDTO() {
  return {
    id: uuidv4(),
    title: '',
    description: '',
    idObject: '',
    date: new Date(),
    category: 0,
    userID: '',
  }
}

export function fromModel(d: Task): TaskDTO {
  return {
    id: d.id,
    title: d.title,
    description: d.description,
    idObject: d.idObject,
    date: d.date,
    category: d.category,
    userID: d.userID,
  }
}

export function toModel(d: TaskDTO): Task {
  return new Task(d)
}
