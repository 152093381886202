import React, { useEffect, useState } from 'react'
import { Field } from '../../components/table'
import { Box, Modal } from '@mui/material'
import { Actions, Pager } from '../../components/table/types'
import seeIcon from '../../assets/user-table/view.svg'
import completeIcon from '../../assets/user-table/completed.svg'
import deleteIcon from '../../assets/user-table/delete.svg'
import editIcon from '../../assets/user-table/edit.svg'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { getTrainingContainer } from '../../container/training-module'
import {
  ITRainingService,
  IUserTrainingService,
  TRAINING_SERVICE_KEY,
  USER_TRAINING_SERVICE_KEY,
} from '../../modules/training'

import { Query, QueryParam } from '../../common/api/Query'
import { ROUTE_TRAINING } from '../../routes/routes-constants'
import {
  emptyUserTrainingDTO,
  UserTrainingDTO,
} from '../../modules/training/models/UserTrainingDTO'
import { TrainingDTO } from '../../modules/training/models/TrainingDTO'
import { UserTraining } from '../../modules/training/models/UserTraining'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { User } from '../../modules/users/models/User'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { reduceString } from '../../common/utils/strings'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { AssignTrainingForm } from './AssignTrainingForm'
import { CustomModal } from '../../components/modal/CustomModal'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { TableContainer } from '@mui/material'
import { Table as TrainingTable } from '@mui/material'
import { HeadTreatment } from '../../components/table/HeadTreatment'
import { BodyTreatment } from '../../components/table/BodyTreatment'
import { Pagination } from '../../components/table/Pagination'
import { useNavigate } from 'react-router-dom'

const userContainer = getUserContainer()
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userTrainingService =
  getTrainingContainer().get<IUserTrainingService>(USER_TRAINING_SERVICE_KEY)
const trainingService = getTrainingContainer().get<ITRainingService>(TRAINING_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)

export interface TrainingTable extends UserTrainingDTO {
  title: string
  description: string
  image: string
  id: string
  trainingID: string
  startDate: Date
  finishDate: Date
  frecuency: number
  series: number
  repsString: string
  minReps: number
  maxReps: number
  repsEspecs: string
  completed: boolean
  userID: string
}

type TableTrainingsProps = {
  id?: string
}

export function Table(props: TableTrainingsProps): JSX.Element {
  const { t } = useTranslation('translation')
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  const [user, setUser] = useState<User>()
  const [userTraining, setUserTraining] = useState<UserTrainingDTO[]>([])
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [training, setTraining] = useState<TrainingDTO[]>([])
  const [completeTraining, setCompleteTraining] = useState<TrainingTable[]>([])
  const [currentUserTraining, setCurrentUserTraining] = useState<UserTrainingDTO>(
    emptyUserTrainingDTO()
  )
  const [openModalAssignTraining, setOpenModalAssignTraining] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const innerWidth = window.innerWidth
  console.log('couNT', count)

  useEffect(() => {
    if (!loggedUser?.id) return
    userService.getByID(loggedUser?.id).subscribe((res) => {
      res && setUser(res)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    let auxTable: TrainingTable[] = []
    userTraining.forEach((ut) => {
      const filter = training.filter((item) => item.id === ut.trainingID)
      let aux: TrainingTable
      aux = {
        title: filter.length > 0 ? t(filter[0].title) : '',
        description: filter.length > 0 ? t(filter[0].description) : '',
        image: filter.length > 0 ? filter[0].image : '',
        id: ut.id,
        trainingID: ut.trainingID,
        startDate: ut.startDate,
        finishDate: ut.finishDate,
        frecuency: ut.frecuency,
        series: ut.series,
        repsString:
          ut.minReps == ut.maxReps ? ut.minReps.toString() : ut.minReps + '-' + ut.maxReps,
        minReps: ut.minReps,
        maxReps: ut.maxReps,
        repsEspecs: ut.repsEspecs,
        typeOfRepeating: ut.typeOfRepeating,
        completed: ut.completed,
        userID: ut.userID,
        recurrence: ut.recurrence,
      }
      auxTable.push(aux)
    })
    setCompleteTraining(auxTable)
    setIsLoading(false)

    return () => {
      setCompleteTraining([])
    }
  }, [training])

  useEffect(() => {
    trainingService.getFilteredList(new Query({})).subscribe((res) => {
      if (!res) return
      setTraining(res.items)
    })

    return () => {
      setTraining([])
    }
  }, [userTraining])

  useEffect(() => {
    if (props.id) {
      userTrainingService
        .getFilteredList(
          new Query({
            query: [new QueryParam<UserTraining>('userID', props.id)],
            pager: { offset: page * rowsPerPage, limit: rowsPerPage },
            sort: [{ field: 'startDate' }],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setUserTraining(res.items)
          setCount(res.count)
        })
    } else {
      if (!loggedUser) return
      userTrainingService
        .getFilteredList(
          new Query({
            query: [new QueryParam<UserTraining>('userID', loggedUser.id)],
            pager: { offset: page * rowsPerPage, limit: rowsPerPage },
            sort: [{ field: 'startDate' }],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setUserTraining(res.items)
          setCount(res.count)
        })
    }

    return () => {
      setUserTraining([])
    }
  }, [isLoading, openModalAssignTraining])

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setRowsPerPage(10)
      return
    }
    setRowsPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  const handlerSeeTraining = (id: UserTraining) => {
    navigate(`${ROUTE_TRAINING}/${id.id}`)
  }

  const handlerEdit = (ut: UserTrainingDTO) => {
    setCurrentUserTraining(ut)

    setEdit(true)
    setOpenModalAssignTraining(true)
  }

  const handlerCompleteTraining = (a: UserTrainingDTO) => {
    const currenUser = a
    userTraining.forEach((ut) => {
      if (a?.id === ut.id) {
        const newUserTraining = Object.assign({ ...currenUser }, { completed: true })
        userTrainingService.update(newUserTraining).subscribe(() => setIsLoading(!isLoading))
      }
    })
  }

  const handlerCloseAssignTraining = () => {
    setOpenModalAssignTraining(false)
  }

  const removeTraining = (a: UserTrainingDTO) => {
    setCurrentUserTraining(a)
    setOpenDeleteModal(true)
  }
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }
  const handleDeleteTraining = () => {
    if (currentUserTraining?.id)
      userTrainingService.delete(currentUserTraining.id).subscribe((_) => {
        statusService.sendStatus({ variant: 'success' })
        setIsLoading(true)
      })
    setOpenDeleteModal(false)
    setIsLoading(!isLoading)
  }

  const fields: Field<TrainingTable>[] = [
    {
      name: 'title',
      label: t('title'),
    },
    {
      name: 'description',
      label: t('description'),
      renderFunc: (f, i) => reduceString(i.description, 50),
    },
    {
      name: 'startDate',
      label: t('date'),
      renderFunc: (f, i) => new Date(i.startDate).toLocaleDateString(),
    },

    {
      name: 'repsString',
      label: t('repetitions'),
    },
    {
      name: 'completed',
      label: t('completed'),
      renderFunc: (f, i) => {
        return (
          <span style={{ color: i.completed ? '#a9ba37' : '#9f3a38' }}>
            {' '}
            {t(i.completed ? 'completed' : 'notComplete')}
          </span>
        )
      },
    },
  ]

  const actions: Actions<any> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: handlerSeeTraining,
        icon: seeIcon,
        label: 'see',
      },
      {
        handler: (ut) => handlerCompleteTraining(ut),
        icon: completeIcon,
        label: 'complete',
        hidden: () => !!props.id,
      },
      {
        handler: (ut) => handlerEdit(ut),
        icon: editIcon,
        label: 'edit',
        hidden: () => !props.id,
      },
      {
        handler: (ut) => removeTraining(ut),
        icon: deleteIcon,
        label: 'delete',
        hidden: () => !props.id,
      },
    ],
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: innerWidth > 900 ? (openMenuLeft ? '50px' : '') : '',
      }}
    >
      <Box
        style={{
          maxWidth:
            innerWidth > 900 ? (openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)') : '95%',
          position: 'relative',
        }}
        className={genericStyle.pageContainer}
      >
        <div className={genericStyle.container}>
          <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
            <CustomModal
              title={t('deleteTraining')}
              warningText={t('irreversibleTrainingAction')}
              handleClose={handleCloseDeleteModal}
              handleSave={handleDeleteTraining}
            />
          </Modal>
          {openModalAssignTraining && (
            <AssignTrainingForm
              id={props.id}
              trainingID={edit ? currentUserTraining.id : undefined}
              open={openModalAssignTraining}
              handleClose={handlerCloseAssignTraining}
            />
          )}
          <Box mt={3} width={'100%'}>
            {/*<AppTable
          items={completeTraining}
          rowKeyField="id"
          fields={fields}
          actions={actions}
          pager={pager}
        />*/}
            <TableContainer
              sx={{
                overflowX: openMenuLeft && innerWidth > 1500 ? 'hidden' : 'auto',
                padding: '0 50px',
              }}
            >
              <TrainingTable
                sx={{
                  border: 'none',
                  background: 'transparent',
                  width: '100%',
                  margin: 0,
                  '& td': {
                    borderWidth: 1,
                    borderStyle: 'dotted',
                    borderColor: '#2E7161',
                    '&:last-child': {
                      border: 'none',
                    },
                    '&:first-child': {
                      borderLeftWidth: 1,
                      borderLeft: 'none',
                      borderColor: '#2E7161',
                      color: '#2E7161',
                      fontFamily: 'Montserrat-SemiBold, sans-serif',
                    },
                  },
                  '& tr': {
                    borderStyle: 'dotted',
                    borderColor: '#2E7161',
                  },
                  '& th': {
                    borderColor: '#2E7161',
                  },
                }}
                style={{ border: 'none' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: openMenuLeft ? '' : '100%',
                    marginLeft: innerWidth < 900 ? '' : openMenuLeft ? 30 : '',
                    marginRight: innerWidth < 900 ? '' : openMenuLeft ? 30 : '',
                  }}
                >
                  <HeadTreatment fields={fields} actions={actions} />
                  <BodyTreatment
                    actions={actions}
                    fields={fields}
                    items={completeTraining}
                    rowKeyField={'id'}
                    isLoading={isLoading}
                  />
                </div>
              </TrainingTable>
            </TableContainer>

            {userTraining.length < 1 && (
              <p style={{ marginBottom: 0, marginLeft: openMenuLeft ? '75px' : '49px' }}>
                {t('notExercisesAssigned')}
              </p>
            )}
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              width={'100%'}
              alignItems={'center'}
            >
              <Box sx={{ marginLeft: openMenuLeft ? '55px' : '20px' }}>
                {pager && <Pagination {...pager} />}
              </Box>
              {!user?.isPatient && (
                <Box mr={5} display={'flex'} justifyContent={'flex-end'}>
                  <AppButton
                    label={t('assignTraining')}
                    theme={ButtonTheme.NewPrimary}
                    handler={() => {
                      setEdit(false)
                      setOpenModalAssignTraining(true)
                    }}
                    type={'button'}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </div>
      </Box>
    </div>
  )
}
