import { Box } from '@mui/material'
import styles from './Calendar.module.css'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface CustomToolbarProps {
  date: Date
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({ date }) => {
  const { t } = useTranslation()

  return (
    <Box className={styles.headerToolbar}>
      <h2>{date.getFullYear()}</h2>
      <h4>{`${dayjs(date).format('DD/MMMM')}`}</h4>
      <h5>{t(date.toLocaleDateString('en', { weekday: 'long' }))}</h5>
    </Box>
  )
}
export default CustomToolbar
