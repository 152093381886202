import { Training } from './Training'
import { v4 as uuidv4 } from 'uuid'

export interface TrainingDTO {
  id: string
  title: string
  description: string
  image: string
}

export function emptyTrainingDTO(): TrainingDTO {
  return {
    id: uuidv4(),
    title: '',
    description: '',
    image: '',
  }
}

export function fromModel(t: Training): TrainingDTO {
  return {
    id: t.id,
    title: t.title,
    description: t.description,
    image: t.image,
  }
}

export function toModel(d: TrainingDTO): Training {
  return new Training(d)
}
