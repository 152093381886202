import { createContext, useContext } from 'react'
import { emptyFileDTO, FileDTO } from '../../modules/files/models/FileDTO'

export type ProfilePhotoContext = {
  profilePhoto: FileDTO
  setProfilePhoto: (c: FileDTO) => void
}

export const ProfilePhotoContext = createContext<ProfilePhotoContext>({
  profilePhoto: emptyFileDTO(''),
  setProfilePhoto: () => {},
})

export const useProfilePhotoGlobalContext = () => useContext(ProfilePhotoContext)
