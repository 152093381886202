import React from 'react'
import { Box } from '@mui/material'
import styles from './ShortDescription.module.css'
import avatar from '../../assets/new-right-menu/profile.svg'

type ShortDescriptionProps = {
  conversationID: string | undefined
  conversationName: string
  lastMessage: string
  avatarUrl: string
  handlerConversation: (conversationID: string | undefined) => void
  isCurrentConversation: boolean
}

export function ShortDescription(props: ShortDescriptionProps): JSX.Element {
  const handleClick = () => props.handlerConversation(props.conversationID)

  return (
    <Box
      className={containerClassName(props.isCurrentConversation)}
      display="flex"
      onClick={handleClick}
    >
      <Box className={styles.avatarWrapper} flexDirection="column">
        {/*<Avatar
                src={props.avatarUrl}
                style={{color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0'}}
            />*/}
        <img src={avatar} alt={'avatar'} width={40} />
      </Box>
      <Box className={styles.conversationBlock}>
        <h3 className={styles.conversationName}>{props.conversationName}</h3>
      </Box>
    </Box>
  )
}

function containerClassName(isCurrent: boolean): string {
  return styles.conversation + (isCurrent ? ' ' + styles.activeConversation : '')
}
