import { createContext, useContext } from 'react'

export type TitleHeaderContext = {
  title: string
  setTitle: (c: string) => void
}

export const TitleHeaderContext = createContext<TitleHeaderContext>({
  title: '',
  setTitle: () => {},
})

export const useTitleHeaderContext = () => useContext(TitleHeaderContext)
