import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'

export const Input = styled(TextField)({
  '& .MuiFormControl-root': {
    width: '350px',
    height: 35,
  },
  '& .MuiInputBase-root': {
    height: '40px',
    borderRadius: 32,
    color: '#474747',
    background: '#F9F9F9',
    textAlign: 'center',
    fontFamily: 'Montserrat-Regular, sans-serif',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    textTransform: 'none',

    '& fieldset': {
      border: '1px solid #E8E7EC',
      fontFamily: 'Montserrat-Regular',
    },
    '& input': {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px #F9F9F9 inset',
        WebkitTextFillColor: '#474747',
        height: '14px',
      },
      '&:-internal-autofill-selected': {
        backgroundColor: '#F9F9F9 !important',
        color: '#474747 !important',
        height: '14px',
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#2E7161',
    },
  },
  '&:hover': {
    background: '#F9F9F9',
    transform: 'scale(1.02)',
    transition: 'all 0.2s ease-in-out',
  },
})
