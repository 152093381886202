import { RouteProps } from '../../routes/AppRouter'
import { PatientFormView as View } from '../../features/form-patients'
import React from 'react'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import logoCareme from '../../assets/caremePlusLogo.svg'
import style from '../layout/Main.module.css'
import { Box } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'

type PatientFormProps = {
  edit?: boolean
} & RouteProps

export function PatientFormView(props: PatientFormProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()
  const { id } = useParams()
  const location = useLocation()

  setTitle(title)
  const state: PatientFormProps = location?.state as PatientFormProps

  return (
    <>
      <View id={id || ''} edit={!!state.edit} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <img src={logoCareme} alt="Logo Careme" className={style.logo} />
      </Box>
    </>
  )
}
