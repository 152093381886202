import { RouteProps } from '../../routes/AppRouter'
import React from 'react'
import { CalendarForm as CF } from '../../features/calendar'
import { ROUTE_CREATE } from '../../routes/routes-constants'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import { Box } from '@mui/material'
import logoCareme from '../../assets/caremePlusLogo.svg'
import style from '../layout/Main.module.css'
import { useLocation, useParams } from 'react-router-dom'

type CalendarFormProps = {
  date?: Date
  currentUrl?: string
  selectedPatient?: string
} & RouteProps

export function CalendarForm(props: CalendarFormProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()
  const { id } = useParams()
  const location = useLocation()

  setTitle(title)
  const state: CalendarFormProps = location?.state as CalendarFormProps

  return (
    <>
      <CF
        id={id === ROUTE_CREATE ? undefined : id}
        date={state.date}
        currentUrl={state.currentUrl}
        selectedPatient={state.selectedPatient}
      />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <img src={logoCareme} alt="Logo Careme" className={style.logo} />
      </Box>
    </>
  )
}
