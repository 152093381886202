import dayjs from 'dayjs'
import es from 'dayjs/locale/es'
import en from 'dayjs/locale/en'

switch (navigator.language) {
  case 'es':
    dayjs.locale(es)
    break
  case 'en':
  default:
    dayjs.locale(en)
    break
}
