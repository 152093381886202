import { Container, ContainerItem } from '../common/container/Container'

import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { MEDIA_API_KEY, MEDIA_MODULE, MEDIA_SERVICE_KEY } from '../modules/media'
import { MediaApi } from '../modules/media/api/MediaApi'
import { MediaService } from '../modules/media/services/MediaService'
import { MediaProps } from '../modules/media/container'

let container: Container

function init(p: MediaProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(MEDIA_API_KEY, new ContainerItem({ instance: new MediaApi() }))

  items.set(
    MEDIA_SERVICE_KEY,
    new ContainerItem({ instance: new MediaService({ apiKey: MEDIA_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getMediaContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[MEDIA_MODULE],
      },
    })
  }

  return container
}
