import { Line } from 'react-chartjs-2'
import React, { useEffect, useState } from 'react'
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScriptableContext,
  Title,
  Tooltip,
} from 'chart.js'
import { DateFilter } from '../../features/statistics/StatisticsModal'

interface LineChartProps {
  titulo: string
  data: {
    id: string
    userId: string
    value: number
    date: Date
  }[]
  dataSetsNames: string[]
  eje: boolean
  filterDate: DateFilter
  graphicsPoints: number
}

const LineChart = (props: LineChartProps): JSX.Element => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  )

  var labels: string[] = ['', '', '', '', '', '', '']

  var value: number[] = []
  var date: string[] = []

  const [filterDate, setFilterDate] = useState<number[]>(value.slice(0, props.data.length))
  const [filterLabels, setFilterLabels] = useState<string[]>(date.slice(0, props.data.length))
  //const [isLoading, setIsLoading] = useState<boolean>(true)
  props.data.forEach((element, index) => {
    value.push(element.value)
    date.push(new Date(element.date).toLocaleDateString())
  })

  //const xx = new Date(props.data[0]?.date).toLocaleDateString()

  useEffect(() => {
    let auxFirstDate = 0
    let auxFinishDate = 0
    let n = 0
    if (props.eje) {
      date.forEach((element, index) => {
        if (element === props.filterDate.startDate.toLocaleDateString()) {
          auxFirstDate = index
        }
        if (
          element === props.filterDate.finishDate.toLocaleDateString() ||
          (index + 1 === props.data.length && n === 0)
        ) {
          auxFinishDate = index
          n++
        }
      })
    }

    setFilterDate(value.slice(auxFirstDate, auxFinishDate + 1))
    setFilterLabels(date.slice(auxFirstDate, auxFinishDate + 1))
  }, [props.filterDate.startDate, props.filterDate.finishDate])

  //const filterDate = value.slice(firstdate, finishdate + 1)
  //const filterLabels = date.slice(firstdate, finishdate + 1)
  const filterDateWeek = value.slice(value.length - 7, value.length)

  const colors = [
    'rgba(206, 224, 62, 1)',
    'rgba(190,62,185, 1)',
    'rgba(196,104,255, 1)',
    'rgba(25,185,187, 1)',
  ] //Color donde empieza el degradado
  const colorsGradient = [
    'rgba(41, 160, 169, 1)',
    'rgba(190,62,185, 1)',
    'rgba(196,104,255, 1)',
    'rgba(25,185,187, 1)',
  ] //color degradado del principal

  const options = {
    responsive: true,
    plugins: {
      Line: {
        height: 180,
      },
      legend: {
        display: false,
      },
      title: {
        display: props.eje,
        text: props.titulo,
      },
    },
    scales: {
      x: {
        display: props.eje,
      },
      y: {
        display: props.eje,
      },
    },
    elements: {
      point: {
        radius: props.graphicsPoints,
      },
    },
  }

  var datasets: any[] = []
  const data = () => {
    //props.data.forEach((element, index) => {
    datasets.push({
      label: props.dataSetsNames[0],
      data: filterDateWeek,
      borderColor: (context: ScriptableContext<'line'>) => {
        const ctx = context.chart.ctx
        const gradient = ctx.createLinearGradient(0, 50, 0, 400)
        gradient.addColorStop(0, colors[0])
        gradient.addColorStop(1, colorsGradient[0])
        return gradient
      },
      backgroundColor: (context: ScriptableContext<'line'>) => {
        const ctx = context.chart.ctx
        const gradient = ctx.createLinearGradient(0, 50, 0, 400)
        gradient.addColorStop(0, colors[0])
        gradient.addColorStop(1, colorsGradient[0])
        return gradient
      },
      fill: true,
    })
    //})

    return {
      labels,
      datasets,
    }
  }

  const datasetsModal: any[] = []
  const dataModal = () => {
    // new Array(filterDate).forEach((element, index) => {
    datasetsModal.push({
      label: filterLabels[0],
      data: filterDate,
      borderColor: (context: ScriptableContext<'line'>) => {
        const ctx = context.chart.ctx
        const gradient = ctx.createLinearGradient(0, 50, 0, 400)
        gradient.addColorStop(0, colors[0])
        gradient.addColorStop(1, colorsGradient[0])
        return gradient
      },
      backgroundColor: (context: ScriptableContext<'line'>) => {
        const ctx = context.chart.ctx
        const gradient = ctx.createLinearGradient(0, 50, 0, 400)
        gradient.addColorStop(0, colors[0])
        gradient.addColorStop(1, colorsGradient[0])
        return gradient
      },
      fill: true,
    })
    //})

    datasets = datasetsModal
    labels = filterLabels

    return {
      labels,
      datasets,
    }
  }

  return (
    <>
      <Line options={options} data={props.eje ? dataModal() : data()} />
      {/* <Line options={options} data={ data()} />*/}
    </>
  )
}
export default LineChart
