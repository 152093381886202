import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'whatwg-fetch'
import React from 'react'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'
import { prepareModules } from './modules'
import './i18n'
import './config/init-dayjs'
import { cirpaTheme } from './common/utils/styles'
import { ThemeProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { createRoot } from 'react-dom/client'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BrowserRouter as Router } from 'react-router-dom'

prepareModules()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <Router>
    <React.StrictMode>
      <ThemeProvider theme={cirpaTheme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={navigator.language.split('-')[0]}
        >
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            maxSnack={10}
          >
            <App />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </React.StrictMode>
  </Router>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
