export type AppointmentProps = {
  id: string | undefined
  title: string
  place: string
  date: Date
  startCron?: Date
  finishCron?: Date
  userID: string
  doctorID: string
  urlVideoConf: string
  warning: boolean
  typeOf: number
}

export class Appointment {
  private readonly _id: string | undefined
  private readonly _title: string
  private readonly _place: string
  private readonly _date: Date
  private readonly _startCron: Date | undefined
  private readonly _finishCron: Date | undefined
  private readonly _userID: string
  private readonly _doctorID: string
  private readonly _urlVideoConf: string
  private readonly _warning: boolean
  private readonly _typeOf: number

  constructor(p: AppointmentProps) {
    this._id = p.id
    this._title = p.title
    this._place = p.place
    this._date = p.date
    this._startCron = p.startCron
    this._finishCron = p.finishCron
    this._userID = p.userID
    this._doctorID = p.doctorID
    this._urlVideoConf = p.urlVideoConf
    this._warning = p.warning
    this._typeOf = p.typeOf
  }

  get id(): string | undefined {
    return this._id
  }

  get title(): string {
    return this._title
  }

  get place(): string {
    return this._place
  }

  get date(): Date {
    return this._date
  }

  get startCron(): Date | undefined {
    return this._startCron
  }

  get finishCron(): Date | undefined {
    return this._finishCron
  }

  get userID(): string {
    return this._userID
  }

  get doctorID(): string {
    return this._doctorID
  }

  get urlVideoConf(): string {
    return this._urlVideoConf
  }

  get warning(): boolean {
    return this._warning
  }

  get typeOf(): number {
    return this._typeOf
  }
}

export interface AppointmentQuery {
  userID: string
  doctorID: string
}
