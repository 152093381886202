import { TransportType } from '../../../common/enums/TransportType'
import { Notification } from './Notification'
import { DeliveryStatus } from '../enums/DeliveryStatus'
import { EventType } from '../enums/EventType'

export interface NotificationDTO {
  id?: string
  title: string
  message: string
  recipientID: string
  senderID: string
  sendingStatus: DeliveryStatus
  createdAt: Date
  notType: EventType
  transportType: TransportType
}

export function fromModel(n: Notification): NotificationDTO {
  return {
    id: n.id,
    title: n.title,
    message: n.message,
    recipientID: n.recipientID,
    senderID: n.senderID,
    sendingStatus: n.sendingStatus,
    createdAt: n.createdAt,
    notType: n.notType,
    transportType: n.transportType,
  }
}

export function toModel(d: NotificationDTO): Notification {
  return new Notification({
    id: d.id,
    title: d.title,
    message: d.message,
    recipientID: d.recipientID,
    senderID: d.senderID,
    sendingStatus: d.sendingStatus,
    createdAt: new Date(d.createdAt),
    notType: d.notType,
    transportType: d.transportType,
  })
}
