import { Box, Button, Typography } from '@mui/material'
import styles from './CreateFamiliarView.module.css'
import { Input } from '../../pages/user-profile/userProfileStylesMui'
import React from 'react'
import { Familiar } from '../../hooks/useFamiliars'
import { useTranslation } from 'react-i18next'

interface ModalNewFamiliarProps {
  familiar: Familiar
  addFamiliar: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClose: () => void
  saveData: (e: React.FormEvent) => void
}

export const ModalNewFamiliar: React.FC<ModalNewFamiliarProps> = ({
  familiar,
  addFamiliar,
  onClose,
  saveData,
}) => {
  const { t } = useTranslation()

  return (
    <Box className={styles.modalNewFamiliarContainer}>
      <Box className={styles.headerNewFamiliar}>
        <Typography className={styles.headerTitle}>{t('newFamiliar')}</Typography>
      </Box>
      <Box>
        <Box mb={1} className={styles.textFieldBox} display="flex" gap={2}>
          <Box flex={1}>
            <Typography className={styles.inputTitle}>{t('name')}</Typography>
            <Input
              id={'firstName'}
              name="firstName"
              className={styles.textField}
              value={familiar.firstName}
              onChange={addFamiliar}
              type="text"
              required
              variant={'outlined'}
              size={'small'}
            />
          </Box>
          <Box flex={3}>
            <Typography className={styles.inputTitle}>{t('lastName')}</Typography>
            <Input
              id={'lastName'}
              name="lastName"
              className={styles.textField}
              value={familiar.lastName}
              onChange={addFamiliar}
              type="text"
              required
              variant={'outlined'}
              size={'small'}
            />
          </Box>
        </Box>
        <Box mb={1} className={styles.textFieldBox} display="flex" gap={2}>
          <Box flex={1}>
            <Typography className={styles.inputTitle}>{t('email')}</Typography>
            <Input
              id={'email'}
              name="email"
              className={styles.textField}
              value={familiar.email}
              type="email"
              onChange={addFamiliar}
              required
              variant={'outlined'}
              size={'small'}
            />
          </Box>
          <Box flex={3}>
            <Typography className={styles.inputTitle}>{t('phone')}</Typography>
            <Input
              id={'phone'}
              name="phone"
              className={styles.textField}
              value={familiar.phone}
              onChange={addFamiliar}
              type="text"
              required
              variant={'outlined'}
              size={'small'}
            />
          </Box>
        </Box>
      </Box>
      <Box id={'Form y Buttons'} display="flex" justifyContent={'flex-end'} mt={4}>
        <Box
          className={styles.buttonContainer}
          display="flex"
          paddingLeft="40px"
          paddingBottom="5px"
        >
          <Button className={styles.buttonSubmit} type={'button'} onClick={saveData}>
            {t('save')}
          </Button>
          <Button className={styles.buttonCancel} type={'button'} onClick={() => {}}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
