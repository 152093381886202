import i18n from '../../../i18n'
import { LangType } from '../../content/enums/LangType'

export type Related = {
  id: string
  kind: Kind
}

export type LoggedUserProps = {
  id: string
  name: string
  gender: number
  birthDate: Date
  language: LangType
  dni: string
  cip: string
  sessionExpires: Date
  related: Related[]
  permissions: string[]
  roles: string[]
}

export class LoggedUser {
  private readonly _id: string
  private readonly _name: string
  private readonly _gender: number
  private readonly _birthDate: Date
  private _language: LangType
  private readonly _dni: string
  private readonly _cip: string
  private readonly _sessionExpires: Date
  private readonly _related: { id: string; kind: number }[]
  private _permissions: string[]
  private _roles: string[]

  constructor(p: LoggedUserProps) {
    this._id = p.id
    this._name = p.name || ''
    this._gender = p.gender
    this._birthDate = p.birthDate || new Date()
    this._language = p.language
    this._dni = p.dni || ''
    this._cip = p.cip || ''
    this._sessionExpires = new Date(p.sessionExpires)
    this._related = p.related
    this._permissions = p.permissions
    this._roles = p.roles
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get gender(): number {
    return this._gender
  }

  get birthDate(): Date {
    return this._birthDate
  }

  get language(): LangType {
    return this._language
  }

  get dni(): string {
    return this._dni
  }

  get cip(): string {
    return this._cip
  }

  get sessionExpires(): Date {
    return this._sessionExpires
  }

  get related(): Related[] {
    return this._related
  }

  get permissions(): string[] {
    return this._permissions
  }

  set permissions(value: string[]) {
    this._permissions = value
  }

  get roles(): string[] {
    return this._roles
  }

  set roles(value: string[]) {
    this._roles = value
  }
}

export enum Kind {
  RelationKindDoctor = 1,
  RelationKindPatient,
  RelationKindTeacher,
  RelationKindStudent,
  RelationKindParent,
  RelationKindChild,
  RelationKindExtern,
  RelationKindExternViewed,

  NotSet = 100,
}

export const kinds = (): Record<Kind, string> => ({
  [Kind.RelationKindDoctor]: i18n.t('RelationKindDoctor'),
  [Kind.RelationKindPatient]: i18n.t('RelationKindPatient'),
  [Kind.RelationKindTeacher]: i18n.t('RelationKindTeacher'),
  [Kind.RelationKindStudent]: i18n.t('RelationKindStudent'),
  [Kind.RelationKindParent]: i18n.t('RelationKindParent'),
  [Kind.RelationKindChild]: i18n.t('RelationKindChild'),
  [Kind.RelationKindExtern]: i18n.t('RelationKindExtern'),
  [Kind.RelationKindExternViewed]: i18n.t('RelationKindExternViewed'),
  [Kind.NotSet]: i18n.t('NotSet'),
})
