import React, { CSSProperties, useEffect, useState } from 'react'
import styles from './List.module.css'
import resourcesIcon from '../../assets/left_menu/recursos.svg'
import libraryIcon from '../../assets/left_menu/library.svg'
import eventIcon from '../../assets/left_menu/icono-calendario-verde.svg'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import { capitalize } from '../../common/utils/strings'
import rightArrow from '../../assets/left_menu/ico-arrow-view.svg'
import { EventService } from '../../modules/calendar/services/EventService'
import { EVENT_SERVICE_KEY } from '../../modules/calendar'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { getFormContainer } from '../../container/form-module'
import { getCalendarContainer } from '../../container/calendar-module'
import { eventType, EventType } from '../../modules/notifications/enums/EventType'
import { ROUTE_ARTICLES, ROUTE_RESOURCES } from '../../routes/routes-constants'
import { DeliveryStatus } from '../../modules/notifications/enums/DeliveryStatus'
import { useNavigate } from 'react-router-dom'

const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)
const formContainer = getFormContainer()
const userFormService = formContainer.get<UserFormService>(USER_FORM_SERVICE_KEY)

export type ActivityWidgetProps = {
  id: string | undefined
  source: EventType
  title: string
  description?: string
  date: Date
  creator?: string
  url: string
  status?: DeliveryStatus
}

export function ActivityWidget(props: ActivityWidgetProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [status, setStatus] = useState<string>('')
  const [statusStyle, setStatusStyle] = useState<CSSProperties>()

  function getIcon(type: EventType): JSX.Element {
    switch (type) {
      case EventType.Resource:
        return <img className={styles.icon} src={resourcesIcon} alt={resourcesIcon} />
      case EventType.Article:
        return <img className={styles.icon} src={libraryIcon} alt={libraryIcon} />
      case EventType.Event:
        return <img className={styles.icon} src={eventIcon} alt={eventIcon} />
    }
    return <></>
  }

  const handleOnClick = () => {
    switch (props.source) {
      case EventType.Article:
        navigate(`${ROUTE_ARTICLES}/${props.url}`)
        break
      case EventType.Resource:
        navigate(ROUTE_RESOURCES)
        break
      case EventType.Event:
        break
    }
  }

  useEffect(() => {
    /*   if (props.source !== SourceType.FormsNewUserForm) {
      return
    }*/
    if (props.status == DeliveryStatus.InProgress) {
      setStatus('pending')
      setStatusStyle({
        margin: '0 0 2px 10px',
        padding: '5px 50px 5px 5px',
        backgroundColor: 'rgba(219,83,83,0.11)',
        color: '#BB3D3D',
        fontWeight: 'normal',
      })
      return
    }
    setStatus('completed')
    setStatusStyle({
      margin: '0 0 2px 10px',
      padding: '5px 50px 5px 5px',
      backgroundColor: 'rgba(83,219,90,0.11)',
      color: '#10A64A',
      fontWeight: 'normal',
    })
  }, [])

  return (
    <>
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} flexDirection={'row'}>
          <Box className={styles.notificationBox}>
            <Box display={'flex'} flexDirection={'row'} margin={'0 0 5px 10px'} padding={'5px'}>
              {getIcon(props.source)}
              <h2 style={{ color: '#a9ba37', margin: '0 0 0 5px', padding: '0' }}>
                {t(eventType()[props.source])}
              </h2>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <h2 style={{ margin: '0 0 5px 10px', padding: '0' }}>{props.title}</h2>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <h4 style={{ margin: '0 0 0 10px', padding: '0' }}>{props.creator}</h4>
              <h4 style={{ margin: '0 0 2px 10px', padding: '0' }}>
                {capitalize(dayjs(props.date).locale('es').format('dddd, DD MMMM YYYY'))}
              </h4>
            </Box>
            <Box className={styles.containerFooter} style={{ justifyContent: 'space-between' }}>
              <Box>
                <h4 style={statusStyle}>{t(status)}</h4>
              </Box>
              <Box
                style={{ margin: '0', padding: '0', cursor: 'pointer' }}
                display={'flex'}
                flexDirection={'row'}
                className={styles.containerFooter}
                onClick={handleOnClick}
              >
                <h3 style={{ margin: '0', padding: '0' }}>{t('see')}</h3>
                <img
                  style={{ margin: '0 0 0 5px', padding: '0' }}
                  src={rightArrow}
                  alt={rightArrow}
                  className={styles.arrowRight}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
