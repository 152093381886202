import React, { useEffect, useState } from 'react'
import { ViewProps } from './types'

import style from './WeekView.module.css'
import dayjs from 'dayjs'
import { dateToDateString, getMonday } from '../../common/utils/date'
import { Event as E } from '../../modules/calendar/models/Event'
import { Query, QueryParam } from '../../common/api/Query'
import { Box, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { getUserContainer } from '../../container/user-module'
import { capitalize, reduceString } from '../../common/utils/strings'
import { CalendarModal } from './CalendarModal'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY, LOGGED_USER_SERVICE_KEY } from '../../modules/users/container'
import { getCalendarContainer } from '../../container/calendar-module'
import { EventService } from '../../modules/calendar/services/EventService'
import { EVENT_SERVICE_KEY } from '../../modules/calendar'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { useTranslation } from 'react-i18next'

const userContainer = getUserContainer()
const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)

const hours = (): string[] => {
  const hourRange = []
  for (let i = 0; i < 24; i++) {
    if (i < 10) {
      hourRange.push('0' + i + ':00')
    } else {
      hourRange.push(i + ':00')
    }
  }
  return hourRange
}

type WeekViewProps = { id: string | undefined } & ViewProps

type WeekEvent = {
  event: E
}
const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
export function WeekView(props: WeekViewProps) {
  const { t } = useTranslation()
  const [selectedEvent, setSelectedEvent] = useState<E>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [events, setEvents] = useState<E[]>([])
  const [monday, setMonday] = useState<Date>(getMonday(props.selectedDate))
  const loggedUser = loggedUserService.get()

  const circle = userContainer.get<ICircleService>(CIRCLE_SERVICE_KEY).getActive()

  useEffect(() => {
    const finishDate = new Date(monday)
    finishDate.setDate(finishDate.getDate() + 7)
    eventService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<E>('startDate', monday.toISOString()),
            new QueryParam<E>('finishDate', finishDate.toISOString()),
          ],
        }),
        props.id ? props.id : loggedUser?.id || ''
      )
      .subscribe((res) => {
        setEvents([...res.items])
      })
  }, [monday])

  useEffect(() => {
    const tmpMonday = getMonday(props.selectedDate)
    tmpMonday.getTime() !== monday.getTime() && setMonday(tmpMonday)
  }, [props.selectedDate])

  const handleCloseModal = () => setOpenModal(false)

  const editEvent = (e: E) => {
    props.handlerEdit(e)
    setOpenModal(false)
  }

  const deleteEvent = (e: E) => {
    props.handlerRemove(e)
    setOpenModal(false)
  }

  function WeekEvent(props: WeekEvent): JSX.Element {
    const handlerClick = (event: E) => {
      setSelectedEvent(event)
      setOpenModal(true)
    }

    let duration
    if (
      dayjs(props.event.finishDate).format('DD').toString() ===
      dayjs(props.event.startDate).format('DD').toString()
    ) {
      duration =
        Number(dayjs(props.event.finishDate).format('HH')) -
        Number(dayjs(props.event.startDate).format('HH'))
    }

    const getColorByCategory = (category: number) => {
      let color

      switch (category) {
        case 1:
          color = '#f37e31'
          break

        case 2:
          color = '#f37e31'
          break

        case 3:
          color = '#d2617e'
          break

        case 4:
          color = '#eadf33'
          break

        case 5:
          color = '#3fbfcc'
          break

        case 6:
          color = '#91bb5a'
          break

        case 7:
          color = '#815abb'
          break

        case 8:
          color = '#9b8c58'
          break
        default:
          color = '#959595'
          break
      }
      return color
    }

    return (
      <>
        <Box
          className={style.event}
          onClick={() => handlerClick(props.event)}
          style={{
            ['--duration-height' as any]: duration,
            backgroundColor: getColorByCategory(props.event.eventCategory),
          }}
        >
          <h3 className={style.eventTitle}>{reduceString(t(props.event.title), 15)}</h3>
        </Box>
      </>
    )
  }

  const weekDays = (): Date[] => {
    let datesRange = [monday]

    for (let i = 1; i < 7; i++) {
      let newDate = new Date(monday)
      newDate.setDate(monday.getDate() + i)
      datesRange.push(newDate)
    }
    return datesRange
  }

  return (
    <>
      <Table className={style.tableContainer}>
        <TableHead>
          <TableRow>
            <TableCell className={style.weekHeader} style={{ width: '60px' }} />
            {weekDays().map((d, i) => (
              <TableCell className={style.weekHeader}>
                <div className={`${i === 0 ? style.day1 : ''}`} key={dateToDateString(d)}>
                  <div className={style.weekDay}>
                    <div>{dayjs(d).format('DD')}</div>
                    <div>
                      {capitalize(dayjs(d).locale(navigator.language.split('-')[0]).format('ddd'))}
                    </div>
                  </div>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {hours().map((h, i) => (
            <TableRow>
              <TableCell key={i} className={style.hourRow}>
                <div className={style.hour} key={i}>
                  {h}
                </div>
              </TableCell>
              {weekDays().map((d) => (
                <TableCell className={style.eventRow} key={String(d) + String(h)}>
                  {events.map(
                    (event: E) =>
                      dayjs(d).format('DD').toString() ===
                        dayjs(event.startDate).format('DD').toString() &&
                      h.split(':')[0] === dayjs(event.startDate).format('HH').toString() && (
                        <WeekEvent event={event} key={event.id} />
                      )
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selectedEvent && (
        <Modal open={openModal} className={style.eventModal} onClose={handleCloseModal}>
          <CalendarModal
            event={selectedEvent}
            handleClose={handleCloseModal}
            handleEdit={editEvent}
            handleRemove={deleteEvent}
          />
        </Modal>
      )}
    </>
  )
}
