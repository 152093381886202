import { WearableDTO } from './WearableDTO'

export class Wearable {
  private _id: string
  private _idUser: string
  private _mac: string
  private _date: Date

  constructor(p: WearableDTO) {
    this._id = p.id
    this._idUser = p.idUser
    this._mac = p.mac
    this._date = p.date
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get idUser(): string {
    return this._idUser
  }

  set idUser(value: string) {
    this._idUser = value
  }

  get mac(): string {
    return this._mac
  }

  set mac(value: string) {
    this._mac = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }
}
export interface WearableQuery {
  userId: string
}
