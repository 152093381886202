import { createContext, useContext } from 'react'

export type TitleHeaderContext = {
  openMenuLeft: boolean
  setOpenMenuLeft: (c: boolean) => void
}

export const IsOpenMenuLeftContext = createContext<TitleHeaderContext>({
  openMenuLeft: false,
  setOpenMenuLeft: () => {},
})

export const useIsOpenMenuLeftContext = () => useContext(IsOpenMenuLeftContext)
