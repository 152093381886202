import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Intensity } from '../models/Intensity'
import { IntensityDTO } from '../models/IntensityDTO'
import { IUserIntensityApi } from '../api/UserIntensityApi'

type Props = {
  apiKey: symbol
}

export interface IUserIntensityService extends IInit {
  getByID(id: string): Observable<Intensity | undefined>

  getFilteredList(q: Query<Intensity>): Observable<ItemList<Intensity>>

  add(e: IntensityDTO): Observable<Intensity | undefined>

  update(e: IntensityDTO): Observable<Intensity | undefined>

  delete(id: string): Observable<boolean>
}

export class UserIntensityService implements IUserIntensityService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserIntensityApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserIntensityApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: IntensityDTO): Observable<Intensity | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Intensity | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<Intensity>): Observable<ItemList<Intensity>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: IntensityDTO): Observable<Intensity | undefined> {
    return this._api.update(e)
  }
}
