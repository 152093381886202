import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { AppCheckbox } from 'components/app-checkbox/AppCheckbox'
import { CustomTooltip } from 'components/custom-tooltip/CustomTooltip'
import editIcon from 'assets/viewPatients/edit.svg'
import seeIcon from 'assets/articles/see.svg'
import styles from '../CreatePatientView.module.scss'
import { useTranslation } from 'react-i18next'
import { FORM_NAME } from '../constants'
import genericStyle from '../../../common/utils/generic.module.css'
import AvatarProfile from '../../../components/avatar-profile/AvatarProfile'
import { UserDTO } from '../../../modules/users/models/User'
import Grid2 from '@mui/material/Unstable_Grid2'

interface Form {
  name: string
  value: string
}

interface FormsListProps {
  selectedForms: Set<string>
  handleSelectedForms: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleEditSelectedForms: (formType: string) => void
  handleOpenSeeMedicationModal: () => void
  patient: UserDTO
}

const forms: Form[] = [
  { name: FORM_NAME.FOOD, value: 'food' },
  { name: FORM_NAME.CONDUCT, value: 'conduct' },
  { name: FORM_NAME.MEDICATION, value: 'medication' },
]

const FormsList: React.FC<FormsListProps> = ({
  selectedForms,
  handleSelectedForms,
  handleEditSelectedForms,
  handleOpenSeeMedicationModal,
  patient,
}) => {
  const { t } = useTranslation()
  return (
    <Box
      id={'Form List'}
      className={genericStyle.container}
      style={{
        maxHeight: 'fit-content',
        borderTopLeftRadius: 0,
      }}
    >
      <Box className={styles.form}>
        <Box width={'100%'} display={'flex'} m="1rem">
          <Box id={'Seccion Izquierda'} className={styles.avatarContainer}>
            <AvatarProfile />
            <Box maxWidth={'100%'}>
              <Typography className={styles.userName}>
                {`${patient.firstName} ${patient.lastName}`}
              </Typography>
              <Typography className={styles.roleText}>{t('patient')}</Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexGrow={1}
            id={'Listado formularios'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <Box>
              <Box maxWidth="100%" className={styles.headerContainer}>
                <Typography className={styles.headerTitle}>{t('forms')}</Typography>
              </Box>
              <Grid
                mt={2}
                container
                spacing={2}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                {forms.map((form, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={index === 2 ? 12 : 6}
                    md={index === 2 ? 12 : 6}
                    lg={index === 2 ? 12 : 6}
                    container
                    alignItems="center"
                  >
                    <Grid
                      item
                      display="flex"
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      {form.name === FORM_NAME.MEDICATION ? (
                        <AppCheckbox
                          checked={selectedForms.has(form.name)}
                          value={form.name}
                          onChange={handleSelectedForms}
                          style={{ justifyContent: 'flex-start', visibility: 'hidden' }}
                        />
                      ) : (
                        <AppCheckbox
                          checked={selectedForms.has(form.name)}
                          value={form.name}
                          onChange={handleSelectedForms}
                          style={{ justifyContent: 'flex-start' }}
                        />
                      )}

                      <Typography className={styles.checkboxLabel}>{t(form.value)}</Typography>
                      <Box display="flex" justifyContent="center">
                        {form.name === FORM_NAME.MEDICATION ? (
                          <CustomTooltip title={t('see')} placement="right">
                            <img
                              className={styles.iconEdit}
                              src={seeIcon}
                              alt="see"
                              onClick={handleOpenSeeMedicationModal}
                            />
                          </CustomTooltip>
                        ) : (
                          <CustomTooltip title={t('edit')} placement="right">
                            <img
                              className={styles.iconEdit}
                              src={editIcon}
                              alt="edit"
                              onClick={() => handleEditSelectedForms(form.name)}
                            />
                          </CustomTooltip>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
            {/*<Box className={styles.buttonContainer} display="flex" paddingLeft="40px" paddingBottom="5px">
                        <Button className={styles.buttonSubmit} type={'button'} onClick={() => {
                        }}>
                            {t('save')}
                        </Button>
                        <Button className={styles.buttonCancel} type={'button'} onClick={() => {
                        }}>
                            {t('cancel')}
                        </Button>
                    </Box>*/}
            <Box id={'Form y Buttons'} display="flex" justifyContent={'flex-end'} mb={4}>
              <Box
                className={styles.buttonContainer}
                display="flex"
                paddingLeft="40px"
                paddingBottom="5px"
              >
                <Button className={styles.buttonSubmit} type={'button'} onClick={() => {}}>
                  {t('save')}
                </Button>
                <Button className={styles.buttonCancel} type={'button'} onClick={() => {}}>
                  {t('cancel')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FormsList
