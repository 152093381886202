import { Query } from '../../../common/api/Query'
import { Message } from './Message'
import { ConversationStatus } from '../enums/ConversationStatus'
import { ConversationDTO, messageToModel } from './ConversationDTO'

export class Conversation {
  private readonly _id: string | undefined
  private readonly _createdAt: Date
  private readonly _name: string
  private readonly _createdBy: string
  private _userID: string
  private _doctorID: string
  private _messages: Message[]
  private _updatedAt: Date
  private _status: ConversationStatus

  constructor(p: ConversationDTO) {
    this._id = p.id
    this._userID = p.userID
    this._doctorID = p.doctorID
    this._name = p.name
    this._messages = p.messages?.map((m) => messageToModel(m)) || []
    this._createdAt = p.createdAt
    this._updatedAt = p.updatedAt
    this._status = p.status
    this._createdBy = p.createdBy
  }

  get id(): string | undefined {
    return this._id
  }

  get userID(): string {
    return this._userID
  }

  get doctorID(): string {
    return this._doctorID
  }

  get createdBy(): string {
    return this._createdBy
  }

  set userID(userID: string) {
    this._userID = userID
  }

  set doctorID(doctorID: string) {
    this._doctorID = doctorID
  }

  set messages(messages: Message[]) {
    this._messages = messages
  }

  get name(): string {
    return this._name
  }

  get messages(): Message[] {
    if (this._messages) {
      return [...this._messages]
    }

    return this._messages
  }

  removeMessage(id: string) {
    this._messages = this._messages.filter((m) => m.id !== id)
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get updatedAt(): Date {
    return this._updatedAt
  }

  set updatedAt(value: Date) {
    this._updatedAt = value
  }

  get status(): ConversationStatus {
    return this._status
  }

  set status(value: ConversationStatus) {
    this._status = value
  }
}

export interface ConversationQuery {
  userID: string
  doctorID: string
  userID10R2: string
}

export class ConversationParams extends Query<Conversation> {}
