import { Box, Grid, TextField, outlinedInputClasses } from '@mui/material'
import { RouteProps } from 'routes/AppRouter'
import genericStyle from '../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { FormCard } from '../../components/form-card/FormCard'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, FormEvent, useState } from 'react'
import { ROUTE_TRAININGS } from '../../routes/routes-constants'
import styles from './Editor.module.css'
import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { inputLabelClasses } from '@mui/material'
import { emptyUserDTO, UserDTO } from '../../modules/users/models/User'
import { Alert } from '@mui/material'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // Apply styles to the notched outline when the input is focused
          [`& .${outlinedInputClasses.root}.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#a9ba37',
          },
          // Apply styles to the underline after pseudo-element
          '& .MuiInput-underline:after': {
            borderColor: '#a9ba37',
          },
          // Apply styles to the select component
          '& .MuiSelect-select': {
            width: '29rem',
          },
        },
      },
    },
    MuiInputLabel: {
      // Correct component name for label styles
      styleOverrides: {
        root: {
          [`&.${inputLabelClasses.shrink}`]: {
            color: '#a9ba37',
            marginTop: '-1%',
            backgroundColor: 'white',
            padding: '0 5px', // Ensure background does not overlap text
          },
        },
      },
    },
  },
})

export function Editor(props: RouteProps) {
  const { innerWidth } = window
  const { t } = useTranslation()
  const [patient, setPatient] = useState<UserDTO>(emptyUserDTO())
  const [message, setMessage] = useState<string>()
  const navigate = useNavigate()

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPatient(Object.assign({ ...patient }, { [e.target.id]: e.target.value }))
  }

  const handleBack = () => navigate(ROUTE_TRAININGS)

  const validateForm = () => {
    if (
      !patient.email ||
      !patient.diagnosis ||
      !patient.dob ||
      !patient.gender ||
      !patient.lastName ||
      !patient.firstName ||
      !patient.historyRecordNumber
    ) {
      return false
    }
    return true
  }

  function dateIsValid(date: Date) {
    return !Number.isNaN(new Date(date).getTime())
  }

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateForm()) {
      return
    }

    let newPatient = Object.assign(
      { ...patient },
      { assignedID: loggedUserService.get()?.id || '' }
    )

    if (props.id) {
      userService.update(newPatient).subscribe(() => {
        statusService.sendStatus({ variant: 'success' })
        handleBack()
      })
    } else {
      userService.add(newPatient, navigator.language).subscribe((res) => {
        if (res) {
          statusService.sendStatus({ variant: 'success' })
          handleBack()
        }
      })
    }
  }

  const style = () => {
    if (innerWidth > 598) {
      return {
        borderWidth: '1px',
        borderColor: 'lightgrey',
        borderStyle: 'solid',
        margin: '0px 0px 0px -320px',
        padding: '5px 10px 5px 10px',
        width: '290px',
        color: 'grey',
      }
    } else {
      return {
        fontSize: '14px',
        margin: '0px 0px 0px -240px',
        padding: '5px 10px 5px 10px',
        width: '290px',
        color: 'grey',
      }
    }
  }

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <FormCard>
          <form onSubmit={handleSave}>
            <Grid container spacing={3}>
              <Grid item lg={6} className={styles.box}>
                <ThemeProvider theme={theme}>
                  <TextField
                    className={styles.fields}
                    value={patient.firstName}
                    id="title"
                    label={t('title')}
                    variant="outlined"
                    fullWidth={true}
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleChange(e)}
                  />
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <TextField
                    style={{ marginTop: 30 }}
                    multiline
                    fullWidth={true}
                    rows={20}
                    className={styles.fields}
                    value={patient.diagnosis}
                    id="description"
                    name="description"
                    label={t('description')}
                    size={'medium'}
                    variant="outlined"
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleChange(e)}
                  />
                </ThemeProvider>
              </Grid>
              <Box className={styles.buttons} mb={3} display="flex" justifyContent="flex-end">
                <Box style={{ marginRight: 10 }}>
                  <AppButton
                    theme={ButtonTheme.NewSecondary}
                    type={'button'}
                    label={t('back')}
                    handler={handleBack}
                  />
                </Box>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('add')}
                  handler={(e) => handleSave(e)}
                />
              </Box>
            </Grid>
          </form>
        </FormCard>
        {message && (
          <Box mb={3}>
            <Alert className={styles.message} severity="success" key="message" id="message">
              {t(message)}
            </Alert>
          </Box>
        )}
      </Box>
    </>
  )
}
