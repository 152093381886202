import { UserConfig } from './UserConfig'

export interface UserConfigDTO {
  id: string
  sourceType: number
  transportType: number
  userID: string
  notifyBefore: number
}

export function fromModel(uc: UserConfigDTO): UserConfigDTO {
  return {
    id: uc.id,
    sourceType: uc.sourceType,
    transportType: uc.transportType,
    userID: uc.userID,
    notifyBefore: uc.notifyBefore,
  }
}

export function toModel(d: UserConfigDTO): UserConfig {
  return new UserConfig(d)
}
