import React, { useEffect } from 'react'
import { Box, Typography, Button } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { AppCheckbox } from 'components/app-checkbox/AppCheckbox'
import PhoneIcon from '@mui/icons-material/Phone'
import styles from '../CreatePatientView.module.scss'
import { useTranslation } from 'react-i18next'

interface Familiar {
  id: string
  firstName: string
  lastName: string
  phone: string
}

interface FamiliarsListProps {
  familiars: Familiar[]
  selectedFamiliars: Set<number>
  handleSelectFamiliar: (index: number) => void
  onAddFamiliar: () => void
  onRemoveFamiliar: () => void
}

const FamiliarsList: React.FC<FamiliarsListProps> = ({
  familiars,
  selectedFamiliars,
  handleSelectFamiliar,
  onAddFamiliar,
  onRemoveFamiliar,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Familiar Form')
  }, [familiars])

  return (
    <Box id={'Familiar Form'} width="85%">
      <Box maxWidth="100%" className={styles.headerContainer} marginTop="16px">
        <Typography className={styles.headerTitle}>{t('familiars')}</Typography>
      </Box>

      <Box className={styles.textFieldBox}>
        <Box width={'95%'}>
          {familiars.length <= 0 ? (
            <Typography className={styles.noFamiliarsMessage}>
              <span className={styles.typoText}>{t('noFamiliarAdded')}</span>
            </Typography>
          ) : (
            familiars.map((familiar, index) => (
              <Box display={'flex'} key={index} id="Familiar">
                <AppCheckbox
                  checked={selectedFamiliars.has(index)}
                  onChange={() => handleSelectFamiliar(index)}
                />
                <Typography className={styles.typoText} width="70%">
                  {familiar.firstName} {familiar.lastName}
                </Typography>
                <Box display={'flex'} width={'20%'} alignItems={'center'}>
                  <PhoneIcon style={{ marginRight: '4px', color: '#474747' }} />
                  {/* <Typography className={styles.typoText}> {familiar.phone} </Typography>*/}
                </Box>
              </Box>
            ))
          )}
          <Box className={styles.buttonContainer} display="flex">
            <Button
              className={styles.buttonAddFamiliar}
              startIcon={<AddCircleIcon />}
              onClick={onAddFamiliar}
              disabled={familiars.length === 4}
              style={{
                background: familiars.length === 4 ? 'gray' : 'var(--verde-topbar-y-botones)',
              }}
            >
              {t('addFamiliar')}
            </Button>
            <Button
              className={styles.buttonRemoveFamiliar}
              startIcon={<CancelIcon />}
              onClick={onRemoveFamiliar}
              disabled={selectedFamiliars.size === 0}
              style={{
                background: selectedFamiliars.size === 0 ? 'gray' : 'var(--rojo-botones-eliminar)',
              }}
            >
              {t('removeFamiliar')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FamiliarsList
