import { Query } from '../../../common/api/Query'
import { FormField } from './FormField'

export type FormProps = {
  id?: string
  title: string
  description: string
  formFields: FormField[]
  repeating: string
}

export class Form {
  private readonly _id: string | undefined

  private _title: string
  private _description: string
  private _formFields: FormField[]
  private _repeating: string

  constructor(p: FormProps) {
    this._id = p.id
    this._title = p.title
    this._description = p.description
    this._formFields = p.formFields
    this._repeating = p.repeating
  }

  get id(): string | undefined {
    return this._id
  }

  get title(): string {
    return this._title
  }

  set title(value: string) {
    this._title = value
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }

  get repeating(): string {
    return this._repeating
  }

  set repeating(value: string) {
    this._repeating = value
  }

  get formFields(): FormField[] {
    return [...this._formFields]
  }

  addFormField(value: FormField) {
    this._formFields.push(value)
  }

  removeFormField(id: string) {
    this._formFields = this._formFields.filter((ff) => ff.id !== id)
  }
}

export class FormQuery extends Query<Form> {}
