import { CreateFamiliarView } from './CreateFamiliarView'
import { CreatePatientView } from './CreatePatientView'
import { useParams } from 'react-router-dom'
import { RouteProps } from '../../routes/AppRouter'
import React, { useEffect, useState } from 'react'
import { Familiar, useFamiliars } from '../../hooks/useFamiliars'
import { usePatient } from './patient-view/PatientContext'
import { getUserContainer } from '../../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../../modules/users'
import { emptyUserDTO, fromModel, User } from '../../modules/users/models/User'
import { getFormContainer } from '../../container/form-module'
import { IUserFormService, USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { UserFormWithTemplateName } from '../../modules/forms/models/UserFormWithTemplateName'
import { getCircleContainer } from '../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../modules/circle'
import { Box, Tab, Tabs } from '@mui/material'
import styles from './CreatePatientView.module.scss'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import FormList from './patient-view/FormList'
import { useForms } from '../../hooks/useForms'
import { ListUserTrainingForm } from '../form-generator/training-form/ListUserTrainingForm'
import { usePatientData } from '../../hooks/usePatientData'
import { UserTrainingWithTrainingNameDTO } from '../../modules/training/models/UserTrainingWithTrainingNameDTO'
import { UserTrainingWithTrainingName } from '../../modules/training/models/UserTrainingWithTrainingName'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const userFormService = getFormContainer().get<IUserFormService>(USER_FORM_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
export function Editor(props: RouteProps) {
  const { id } = useParams()
  const [isFamiliarViewOpen, setFamiliarViewOpen] = useState(false)
  const [isPatientViewOpen, setPatientViewOpen] = useState(true)
  const [familiars, setFamiliars] = useState<Familiar[]>([])
  const [userForms, setUserForms] = useState<UserFormWithTemplateName[]>([])
  const { patient, setPatient } = usePatient()
  const { handleRemoveFamiliar, isSave } = useFamiliars(familiars)
  const {
    selectedForms,
    handleSelectedForms,
    handleEditSelectedForms,
    handleOpenTrainingModal,
    handleOpenSeeMedicationModal,
  } = useForms(props?.id)
  const { handleChange, handleDateChange, savePatient, handleRecurrence, training, setTraining } =
    usePatientData(familiars, [], props?.id)
  const [trainingSelectedWithUserAndNameTraining, setTrainingSelectedWithUserAndNameTraining] =
    React.useState<UserTrainingWithTrainingNameDTO>()
  const [editTraining, setEditTraining] = useState<UserTrainingWithTrainingName>()
  const [tabIndex, setTabIndex] = useState(0)
  const [openAssignTraining, setOpenAssignTraining] = React.useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (id)
      circleService.getByPatientID(id).subscribe((res) => {
        if (res?.familiars) {
          Promise.all(
            res.familiars.map((familiarId) => userService.getByID(familiarId).toPromise())
          ).then((familiarsInfo) => {
            const filteredFamiliarsInfo = familiarsInfo.filter(
              (familiar): familiar is User => familiar !== undefined
            )
            const familiars: Familiar[] = filteredFamiliarsInfo.map((user) => ({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phone: ' ',
              role: 'familiar',
              notification: [],
            }))
            setFamiliars(familiars)
          })
        }
      })
  }, [id])

  useEffect(() => {
    if (id) {
      userService.getByID(id).subscribe((response) => {
        if (!response) return
        setPatient(fromModel(response))
      })
      userFormService.getWithTemplateNamesByUserID(id).subscribe((response) => {
        if (!response) return
        setUserForms(response.items)
      })
      return
    }
    setPatient(emptyUserDTO())
  }, [id])

  const handlerFamiliars = (familiar: Familiar) => {
    console.log('familiar del editor', familiar)
    setFamiliars([...familiars, familiar])
  }

  const handleOpenFamiliarView = () => {
    console.log('entro')
    setFamiliarViewOpen(true)
    setPatientViewOpen(false)
  }
  const handleCloseFamiliarView = () => {
    setFamiliarViewOpen(false)
    setPatientViewOpen(true)
  }

  useEffect(() => {}, [familiars])

  const handleAddFamiliar = () => {
    setFamiliarViewOpen(isSave)
    if (isSave) {
      setPatientViewOpen(false)
    } else {
      setPatientViewOpen(true)
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const handleOpenAssignTraining = () => {
    setOpenAssignTraining(!openAssignTraining)
  }

  const { openMenuLeft } = useIsOpenMenuLeftContext()

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <Box
        style={{
          maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)',
          minWidth: '1040px',
        }}
        className={genericStyle.pageContainer}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          classes={{ indicator: styles.customIndicator, root: styles.customRootTabs }}
        >
          <Tab
            label={<span className={styles.tabStyle}>{t('userData')}</span>}
            className={`${tabIndex === 0 ? styles.tabActive : styles.tab}`}
          />
          <Tab
            label={<span className={styles.tabStyle}>{t('familiars')}</span>}
            className={tabIndex === 1 ? styles.tabActive : styles.tab}
          />
          <Tab
            label={<span className={styles.tabStyle}>{t('forms')}</span>}
            className={`${tabIndex === 2 ? styles.tabActive : styles.tab}`}
          />
          <Tab
            label={<span className={styles.tabStyle}>{t('trainings')}</span>}
            className={`${tabIndex === 3 ? styles.tabActive : styles.tab} ${styles.lastTab}`}
          />
        </Tabs>
        {tabIndex === 0 && (
          <CreatePatientView
            id={id}
            familiars={familiars}
            isFamiliarViewOpen={isFamiliarViewOpen}
            onAddFamiliar={handleOpenFamiliarView}
            onRemoveFamiliar={handleRemoveFamiliar}
            patient={patient}
            userForms={userForms}
          />
        )}
        {tabIndex === 1 && (
          <CreateFamiliarView
            id={id}
            familiars={familiars}
            onSave={handleAddFamiliar}
            handleAddFamiliar={handlerFamiliars}
            onCancel={handleCloseFamiliarView}
            onAddFamiliar={handleOpenFamiliarView}
            onRemoveFamiliar={handleRemoveFamiliar}
            isFamiliarViewOpen={isFamiliarViewOpen}
          />
        )}
        {tabIndex === 2 && (
          <FormList
            selectedForms={selectedForms}
            handleSelectedForms={handleSelectedForms}
            handleEditSelectedForms={handleEditSelectedForms}
            handleOpenSeeMedicationModal={handleOpenSeeMedicationModal}
            patient={patient}
          />
        )}
        {tabIndex === 3 && (
          <ListUserTrainingForm
            userID={patient.id}
            handleOpenAssignTraining={handleOpenAssignTraining}
            onClose={handleOpenTrainingModal}
            trainingSelected={trainingSelectedWithUserAndNameTraining}
            setTraining={setTraining}
            setEditTraining={setEditTraining}
            patient={patient}
          />
        )}
      </Box>
    </div>
  )
}
