import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { FormEvent, useEffect, useState } from 'react'
import styles from './View.module.css'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { Article } from '../../modules/content/models/Article'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import genericStyle from '../../common/utils/generic.module.css'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { emptyArticleDTO } from '../../modules/content/models/ArticleDTO'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { useNavigate } from 'react-router-dom'

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)

type ArticlesViewProps = {
  id?: string
}

export function View(props: ArticlesViewProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [article, setArticle] = useState<Article>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { openMenuLeft } = useIsOpenMenuLeftContext()

  useEffect(() => {
    if (!isLoading || !props.id) {
      return
    }
    articlesService.getByID(props.id).subscribe((res) => {
      res && setArticle(res)
      setIsLoading(false)
    })
  }, [isLoading])

  const mappedContent = article?.content.split('\n').map((c) =>
    c !== '' ? (
      <p key={c} className={styles.content}>
        {c}
      </p>
    ) : (
      <></>
    )
  )

  const saveArticle = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (props.id) {
      articlesService.update(emptyArticleDTO('')).subscribe(() => goBack())
      return
    }
    articlesService.add(emptyArticleDTO('')).subscribe(() => navigate(ROUTE_ARTICLES))
  }

  const goBack = () => navigate(-1)

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <Box
        style={{
          maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)',
        }}
        className={genericStyle.pageContainer}
      >
        <div className={genericStyle.headerContainer}>
          <Typography>{article?.title}</Typography>
        </div>
        <Box
          sx={{ justifyContent: 'space-between', height: '100%' }}
          className={genericStyle.container}
        >
          {!isLoading ? (
            <Box key="article" className={styles.articleWrapper}>
              {mappedContent}
              <Box key="button" className={styles.backButton}>
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('back')}
                  handler={goBack}
                />
              </Box>
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </div>
  )
}
