import { Wearable } from './Wearable'
import { v4 as uuidv4 } from 'uuid'

export interface WearableDTO {
  id: string
  idUser: string
  mac: string
  date: Date
}

export function emptyWearableDTO(): WearableDTO {
  return {
    id: uuidv4(),
    idUser: '',
    mac: '',
    date: new Date(),
  }
}

export function fromModel(t: Wearable): WearableDTO {
  return {
    id: t.id,
    idUser: t.idUser,
    mac: t.mac,
    date: t.date,
  }
}

export function toModel(d: WearableDTO): Wearable {
  return new Wearable(d)
}
