import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { Box, Checkbox, checkboxClasses } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_CALENDAR, ROUTE_NEWS_WALL } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { emptyEventDTO, EventDTO, fromModel } from '../../modules/calendar/models/EventDTO'
import { getCalendarContainer } from '../../container/calendar-module'
import { EventService } from '../../modules/calendar/services/EventService'
import { EVENT_SERVICE_KEY } from '../../modules/calendar'
import { CronItemMockup } from '../../components/form-card/CronItemMockup'
import { File as F } from '../../modules/files/models/File'
import { getUserContainer } from '../../container/user-module'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { transportTypes } from '../../common/enums/TransportType'
import { Notification as CalendarNotification } from '../../modules/calendar/models/Notification'
import style from './Editor.module.css'
import genericStyle from '../../common/utils/generic.module.css'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { cronString } from '../../modules/forms/enums/Cron'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { Input, InputTextDescription } from '../resource/userProfileStylesMui'
import calendarIcon from '../../assets/calendar/calendar-newEvent.svg'
import TextField from '@mui/material/TextField'
import { EventCategory } from '../../modules/calendar/enums/EventCategory'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

type EditorProps = {
  id?: string
  date?: Date
  currentUrl?: string
  selectedPatient?: string
}

type AutocompleteUserInterface = {
  key: string
  value: string
}

const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

//const fileContainer = getFileContainer()
//const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

//const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

const notificationTypes = transportTypes()

//const notificationContainer = getNotificationContainer()
//const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)

const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)

export function Editor(props: EditorProps) {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()
  const cronTypes = cronString()

  const [event, setEvent] = useState<EventDTO>(emptyEventDTO(props.date, loggedUser?.id || ''))
  const [isFinishReminder, setIsFinishReminder] = useState<boolean>(false)
  const [isRecurrence, setIsRecurrence] = useState<boolean>(false)
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const [files, setFiles] = useState<F[]>([])
  //const [users, setUsers] = useState<Map<string, AutocompleteUserInterface>>(new Map())
  //const [selectedUsers, setSelectedUsers] = useState<AutocompleteUserInterface[]>([])
  const [cron, setCron] = useState<string>('0 0 * * *')
  const [startDateRec, setStartDateRec] = useState<Date>(new Date())
  const [finishDateRec, setFinishDateRec] = useState<Date>(new Date())
  const [hourNotValid, setHourNotValid] = useState<string | undefined>()
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
  const [isDatePickerFinishOpen, setIsDatePickerFinishOpen] = useState<boolean>(false)
  const [isDatePickerUntilOpen, setIsDatePickerUntilOpen] = useState<boolean>(false)
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const navigate = useNavigate()
  // let startDateRec = new Date()
  //let finishDateRec = new Date()
  const handleChange = (s: string, sd: Date, fd: Date) => {
    setCron(s)
    setStartDateRec(sd)
    setFinishDateRec(fd)
  }

  console.log('selected patient', props.selectedPatient)

  /*const getFiles = (ids: string[]): Observable<F[]> =>
      forkJoin(ids.map((id) => fileService.getByID(id)) as unknown) as Observable<F[]>*/

  useEffect(() => {
    if (!props.id) {
      return
    }
    eventService.getByID(props.id).subscribe((res) => {
      if (!res) {
        return
      }
      setEvent(fromModel(res))

      res.repeatingUntil && setIsFinishReminder(true)
    })

    if (!loggedUser?.id) {
      return
    }
    setEvent(Object.assign({ ...event }, { ownerID: loggedUser.id }))
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setEvent(Object.assign({ ...event }, { [e.target.name]: e.target.value }))

  const handleDate = (e: any, name: string) => {
    setHourNotValid(undefined)
    if (!e) return
    let hours = e?.hour()
    if (!hours) return
    if (hours < 7 && e) {
      setHourNotValid('La hora debe ser mayor o igual a las 7:00')
      return
    } else if (hours > 22 && e) {
      setHourNotValid('La hora debe ser menor o igual a las 22:00')
      return
    }

    e && setEvent(Object.assign({ ...event }, { [name]: e.toDate() }))
  }

  const handleDateRec = (startDateRec: Date, finishDateRec: Date) => {
    setEvent(Object.assign({ ...event }, { startCron: startDateRec, finishCron: finishDateRec }))
  }

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case 'finishReminder':
        setIsFinishReminder(!isFinishReminder)
        break
      case 'recurrence':
        setIsRecurrence(!isRecurrence)
        break
      default:
        break
    }
  }

  const handleNotification = (notifications: CalendarNotification[]) =>
    setEvent(Object.assign({ ...event }, { notifications: notifications.map((n) => n.id) }))

  /*  const handleUsers = (value: AutocompleteUserInterface[]) => {
      if (!value) {
        return
      }
      if (value.find((v) => v.key === 'every')) {
        const result = Array.from(users.values()).filter((v) => v.key !== 'every')
        setSelectedUsers(result)
        setEvent(Object.assign({ ...event }, { users: result.map((v) => v.key) }))
        return
      }
      const result = [...selectedUsers, ...value]
      setSelectedUsers(result)
      setEvent(Object.assign({ ...event }, { users: result.map((v) => v.key) }))
    }*/

  const saveEvent = (e: FormEvent<HTMLFormElement>) => {
    // console.log(startDateRec)
    //ShandleDateRec(startDateRec, finishDateRec)

    e.preventDefault()

    //comprobacion de campos required.
    let editCalentar = document.getElementById('EditcalendarEvent') as HTMLFormElement
    if (!editCalentar.checkValidity()) {
      editCalentar.reportValidity()
      return
    }

    const newEvent = Object.assign(
      { ...event },
      {
        users: props.selectedPatient ? [props.selectedPatient] : [loggedUser?.id],
        repeatingUntil: isFinishReminder ? event.repeatingUntil : undefined,
        repeating: cron,
        startCron: startDateRec,
        finishCron: finishDateRec,
        eventCategory: EventCategory.Appointment,
      }
    )

    console.log('newEvent', newEvent)

    if (props.id) {
      eventService.update(newEvent).subscribe(() => {
        if (props.currentUrl === ROUTE_NEWS_WALL) {
          navigate(ROUTE_NEWS_WALL)
          return
        }
        goBack()
      })
    } else {
      eventService.add(newEvent).subscribe(() => {
        if (props.currentUrl === ROUTE_NEWS_WALL) {
          navigate(ROUTE_NEWS_WALL)
          return
        }
        goBack()
      })
    }
  }

  const goBack = () => {
    if (props.currentUrl === ROUTE_NEWS_WALL) {
      navigate(ROUTE_NEWS_WALL)
      return
    }
    navigate(ROUTE_CALENDAR, { state: { selectedDate: props.date } })
  }

  //const removeUser = (id: string) => setSelectedUsers(selectedUsers.filter((u) => u.value !== id))
  const CalendarIcon = () => <img src={calendarIcon} alt="Calendar" />

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <div
        style={{ maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)' }}
        className={genericStyle.pageContainer}
      >
        <div className={genericStyle.container}>
          <form className={style.form} id={'EditcalendarEvent'} onSubmit={(e) => saveEvent(e)}>
            <Box mb={3} className={style.textField} display={'flex'}>
              <p className={style.label}>{`${t('title')}*`}</p>
              <Input
                key={'title'}
                id={'title'}
                name={'title'}
                value={event.title}
                onChange={handleInput}
                required={true}
                size={'small'}
                variant={'outlined'}
                fullWidth={true}
                //InputProps={{ className: classes.input }}
              />
            </Box>

            <Box
              mb={3}
              className={style.textField}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
            >
              <p className={style.label}>{`${t('description')}*`}</p>
              <InputTextDescription
                fullWidth
                id="description"
                name={'description'}
                value={event.description}
                label={''}
                type={'text'}
                onChange={handleInput}
                rows={10}
                required={true}
              />
            </Box>
            <Box
              mb={3}
              display={'flex'}
              justifyContent={'space-between'}
              className={style.datePickerContainer}
            >
              <Box width={'100%'}>
                <Box display={'flex'} alignItems={'center'} className={style.datePicker}>
                  <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                    {t('startDate')}
                  </p>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <DateTimePicker
                      open={isDatePickerOpen}
                      onOpen={() => setIsDatePickerOpen(true)}
                      onClose={() => setIsDatePickerOpen(false)}
                      key={'startDate'}
                      ampm={false}
                      minDate={dayjs(new Date())}
                      format={'DD/MM/YYYY HH:mm'}
                      onChange={(e) => handleDate(e, 'startDate')}
                      value={dayjs(event.startDate)}
                      label={''}
                      onError={(reason) => {
                        switch (reason) {
                          case 'invalidDate':
                            setDateTimePickerError(t('invalidDateMessage'))
                            break
                          case 'minDate':
                            setDateTimePickerError(t('minDateMessage'))
                            break
                        }
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputProps: {
                            sx: {
                              '& fieldset': {
                                borderRadius: 32,
                                border: '1px solid #E8E7EC',
                                fontFamily: 'Montserrat',
                              },
                              '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                              '& .MuiOutlinedInput-root': {
                                border: 'none',
                                '&.Mui-focused fieldset': {
                                  borderColor: '#2E7161',
                                },
                              },
                            },
                            onClick: () => setIsDatePickerOpen(true),
                          },
                        },
                      }}
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box width={'100%'}>
                <Box display={'flex'} alignItems={'center'} className={style.datePicker2}>
                  <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                    {t('finishDate')}
                  </p>

                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <DateTimePicker
                      open={isDatePickerFinishOpen}
                      onOpen={() => setIsDatePickerFinishOpen(true)}
                      onClose={() => setIsDatePickerFinishOpen(false)}
                      key={'finishDate'}
                      ampm={false}
                      minDate={dayjs(event.startDate)}
                      format={'DD/MM/YYYY HH:mm'}
                      onChange={(e) => handleDate(e, 'finishDate')}
                      value={dayjs(event.finishDate)}
                      label={''}
                      onError={(reason) => {
                        switch (reason) {
                          case 'invalidDate':
                            setDateTimePickerError(t('invalidDateMessage'))
                            break
                          case 'minDate':
                            setDateTimePickerError(t('minDateMessage'))
                            break
                        }
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputProps: {
                            sx: {
                              '& fieldset': {
                                borderRadius: 32,
                                border: '1px solid #E8E7EC',
                                fontFamily: 'Montserrat',
                              },
                              '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                              '& .MuiOutlinedInput-root': {
                                border: 'none',
                                '&.Mui-focused fieldset': {
                                  borderColor: '#2E7161',
                                },
                              },
                            },
                            onClick: () => setIsDatePickerFinishOpen(true),
                          },
                        },
                      }}
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
            <Box mb={3}>
              <Box display={'flex'} alignItems={'center'} width={'100%'} mb={3}>
                <Box display={'flex'} alignItems={'center'} width={'100%'}>
                  <h4 style={{ display: 'inline', marginBottom: 0 }}>
                    {t('includeEndDateReminder')}
                  </h4>
                  <Checkbox
                    name={'finishReminder'}
                    checked={isFinishReminder}
                    onChange={handleCheckbox}
                  />
                </Box>
                <Box
                  className={style.textField}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  width={'100%'}
                >
                  <p style={{ marginBottom: 0 }} className={style.label}>{`${t('place')}`}</p>
                  <Input
                    key={'place'}
                    id={'place'}
                    name={'place'}
                    value={event.place}
                    onChange={handleInput}
                    size={'small'}
                    variant={'outlined'}
                    fullWidth={true}
                    //InputProps={{ className: classes.input }}
                  />
                </Box>
              </Box>
              <Box className={style.datePicker} display={'flex'} alignItems={'center'}>
                <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                  {t('startDate')}
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DateTimePicker
                    open={isDatePickerUntilOpen}
                    onOpen={() => setIsDatePickerUntilOpen(true)}
                    onClose={() => setIsDatePickerUntilOpen(false)}
                    key={'repeatingUntil'}
                    ampm={false}
                    format={'DD/MM/YYYY HH:mm'}
                    onChange={(e) => handleDate(e, 'repeatingUntil')}
                    value={dayjs(event.repeatingUntil)}
                    label={''}
                    disabled={!isFinishReminder}
                    onError={(reason) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('invalidDateMessage'))
                          break
                        case 'minDate':
                          setDateTimePickerError(t('minDateMessage'))
                          break
                      }
                    }}
                    slotProps={{
                      textField: {
                        id: 'repeatingUntil',
                        size: 'small',
                        InputProps: {
                          sx: {
                            '& fieldset': {
                              borderRadius: 32,
                              border: '1px solid #E8E7EC',
                              fontFamily: 'Montserrat',
                            },
                            '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                            '& .MuiOutlinedInput-root': {
                              border: 'none',
                              '&.Mui-focused fieldset': {
                                borderColor: '#2E7161',
                              },
                            },
                          },
                          onClick: () => setIsDatePickerUntilOpen(true),
                        },
                      },
                    }}
                    slots={{
                      openPickerIcon: CalendarIcon,
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            <Box mb={3}>
              <p className={style.recurrenceLabel}>{t('recurrence')}</p>
              <Checkbox
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: '#2E7161',
                  },
                }}
                checked={isRecurrence}
                name={'recurrence'}
                onChange={handleCheckbox}
              />
            </Box>
            {isRecurrence && (
              <Box mb={3}>
                <CronItemMockup handleChange={handleChange} />
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end">
              <Box mr={4}>
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('cancel')}
                  handler={goBack}
                />
              </Box>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'submit'}
                label={t('save')}
                handler={saveEvent}
              />
            </Box>
          </form>
        </div>
      </div>
    </div>
  )
}
