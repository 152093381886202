import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTE_PATIENT_FORMS } from '../../routes/routes-constants'
import { FORM_SERVICE_KEY, USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { getFormContainer } from '../../container/form-module'
import { FormService } from '../../modules/forms/services/FormService'
import { getUserContainer } from '../../container/user-module'
import { Query, QueryParam } from '../../common/api/Query'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { UserForm } from '../../modules/forms/models/UserForm'
import genericStyle from '../../common/utils/generic.module.css'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/users/container'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { ReadingStatus } from '../../modules/forms/enums/ReadingStatus'
import { forkJoin, Observable } from 'rxjs'
import { Form } from '../../modules/forms/models/Form'
import { AppTable, Field } from '../../components/table'
import { Actions, Pager } from '../../components/table/types'
import styles from './Table.module.css'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import { reduceString } from '../../common/utils/strings'
import { useNavigate } from 'react-router-dom'

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const formContainer = getFormContainer()
const formService = formContainer.get<FormService>(FORM_SERVICE_KEY)
const userFormService = formContainer.get<UserFormService>(USER_FORM_SERVICE_KEY)

type RowItem = {
  id: string
  title: string
  description: string
  status: ReadingStatus
  date: String | undefined
}

export function Table(): JSX.Element {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [userForms, setUserForms] = useState<ItemList<RowItem>>(emptyList<RowItem>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const navigate = useNavigate()

  const getForms = (ids: string[]): Observable<Form[]> =>
    forkJoin(ids.map((id) => formService.getByID(id))) as unknown as Observable<Form[]>

  useEffect(() => {
    if (!loggedUser) {
      alert('no hay user')
      return
    }
    userFormService
      .getFilteredList(
        new Query({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
          query: [new QueryParam<UserForm>('userID', loggedUser.id)],
          sort: [{ field: 'reading_status' }],
        })
      )
      .subscribe((ufl) => {
        const ids = ufl.items.map((uf) => uf.formID)
        getForms(ids).subscribe((res) => {
          const itemList = emptyList<RowItem>()
          res.map((f, index) => {
            console.log(index)
            itemList.items.push({
              id: ufl.items.find((uf) => uf.formID === f.id)?.id || '',
              title: f.title,
              description: f.description,
              status: ufl.items[index].readingStatus || ReadingStatus.Pending,
              date: ufl.items[index].userFormValues.length
                ? new Date(ufl.items[index].userFormValues[0].date).toLocaleDateString('es-ES')
                : new Date().toLocaleDateString('es-ES'),
            })
          })
          itemList.count = ufl.count
          setUserForms(itemList)
          setCount(ufl.count)
          setIsLoading(false)
        })
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setRowsPerPage(10)
      return
    }
    setRowsPerPage(Number.parseInt(event.target.value))
  }

  const viewPatientForm = (r: RowItem) =>
    navigate(`${ROUTE_PATIENT_FORMS}/${r.id}`, { state: { edit: false } })

  const editPatientForm = (r: RowItem) =>
    navigate(`${ROUTE_PATIENT_FORMS}/${r.id}`, { state: { edit: true } })

  const handlePaginationChange = (e: unknown, value: number) => setPage(value)

  const isCompleted = (r: RowItem): boolean => r.status === ReadingStatus.Completed

  const fields: Field<RowItem>[] = [
    {
      name: 'title',
      label: t('title'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'date',
      label: t('date'),
    },
    {
      name: 'description',
      label: t('description'),
      renderFunc: (f, i) => reduceString(i.description, 60),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'status',
      label: t('status'),
      renderFunc: (f, i) => t(ReadingStatus[i.status].toLowerCase()),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
  ]

  const actions: Actions<RowItem> = {
    actionsColumn: t('action'),
    items: [
      {
        handler: viewPatientForm,
        icon: seeIcon,
        label: 'see',
        hidden: (r) => !isCompleted(r),
      },
      {
        handler: editPatientForm,
        icon: editIcon,
        label: 'edit',
        hidden: (r) => isCompleted(r),
      },
    ],
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <AppTable
        fields={fields}
        items={userForms.items}
        rowKeyField="id"
        actions={actions}
        pager={pager}
      />
    </Box>
  )
}
