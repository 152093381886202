import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'

export const Input = styled(TextField)({
  '& .MuiFormControl-root': {
    width: '225px',
    height: '40px',
  },
  '& .MuiInputBase-root': {
    width: '225px',
    height: '40px',
    borderRadius: '10px',
    background: '#FFF',
    textAlign: 'center',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    textTransform: 'none',
    '& input': {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px #fff inset',
        WebkitTextFillColor: 'default',
      },
      '& input:-internal-autofill-selected': {
        backgroundColor: 'transparent !important',
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#2E7161',
    },
  },
  '&:hover': {
    background: '#FFF',
    transition: 'all 0.2s ease-in-out',
  },
})
