import { Notification } from './Notification'
import { EventCategory } from '../enums/EventCategory'
import { Event as E } from './Event'
import { v4 as uuidv4 } from 'uuid'

export interface EventDTO {
  id: string | undefined
  title: string
  description: string
  startDate: Date
  finishDate: Date
  repeatingUntil: Date | undefined
  repeating: string
  place: string
  idObject: string
  ownerID: string
  isOwnerBusy: boolean
  eventCategory: EventCategory
  users: string[]
  files: string[] | undefined
  notifications: Notification[] | undefined
}

export function emptyEventDTO(date: Date | undefined, ownerID: string) {
  return {
    id: uuidv4(),
    title: '',
    description: '',
    startDate: date || new Date(),
    finishDate: date || new Date(),
    repeatingUntil: undefined,
    repeating: '0 0 0 0 0',
    place: '',
    idObject: '',
    ownerID: ownerID,
    isOwnerBusy: false,
    eventCategory: EventCategory.Medication,
    users: [],
    files: [],
    notifications: [],
  }
}

export function fromModel(e: E): EventDTO {
  return {
    id: e.id,
    title: e.title,
    description: e.description,
    startDate: e.startDate,
    finishDate: e.finishDate,
    repeatingUntil: e.repeatingUntil,
    repeating: e.repeating,
    place: e.place,
    idObject: e.idObject,
    ownerID: e.ownerID,
    isOwnerBusy: e.isOwnerBusy,
    eventCategory: e.eventCategory,
    users: e.users,
    files: e.files,
    notifications: e.notifications,
  }
}

export function toModel(d: EventDTO): E {
  return new E(d)
}
