import { v4 as uuidv4 } from 'uuid'
import { Appointment } from './Appointment'

export interface AppointmentDTO {
  id: string | undefined
  title: string
  place: string
  date: Date
  userID: string
  doctorID: string
  urlVideoConf: string
  warning: boolean
  typeOf: number
}

export function emptyAppointmentDTO() {
  return {
    id: uuidv4(),
    title: '',
    place: '',
    date: new Date(),
    userID: '',
    doctorID: '',
    urlVideoConf: '',
    warning: true,
    typeOf: 1,
  }
}

export function fromModel(d: Appointment): AppointmentDTO {
  return {
    id: d.id,
    title: d.title,
    place: d.place,
    date: d.date,
    userID: d.userID,
    doctorID: d.doctorID,
    urlVideoConf: d.urlVideoConf,
    warning: d.warning,
    typeOf: d.typeOf,
  }
}

export function toModel(d: AppointmentDTO): Appointment {
  return new Appointment(d)
}
