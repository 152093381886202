import { Query } from '../../../common/api/Query'
import { Reaction } from './Reaction'
import { MessageDTO, reactionToModel } from './ConversationDTO'

export class Message {
  private readonly _id: string | undefined
  private readonly _text: string
  private readonly _authorID: string
  private readonly _conversationID: string
  private readonly _createdAt: Date
  private readonly _senderID: string
  private _reactions: Reaction[] | undefined
  private _files: string[] | undefined

  constructor(p: MessageDTO) {
    this._id = p.id
    this._text = p.text
    this._authorID = p.authorID
    this._conversationID = p.conversationID
    this._createdAt = p.createdAt
    this._files = p.files
    this._senderID = p.senderID
    this._reactions = p.reactions?.map((r) => reactionToModel(r))
  }

  get id(): string | undefined {
    return this._id
  }

  get text(): string {
    return this._text
  }

  get senderID(): string {
    return this._senderID
  }

  get authorID(): string {
    return this._authorID
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get reactions(): Reaction[] | undefined {
    if (this._reactions) {
      return [...this._reactions]
    }

    return this._reactions
  }

  addReaction(value: Reaction) {
    if (this._reactions) {
      this._reactions.push(value)
    } else {
      this._reactions = [value]
    }
  }

  removeReaction(userID: string, messageID: string) {
    this._reactions &&
      (this._reactions = this._reactions.filter(
        (r) => r.userID !== userID && r.messageID !== messageID
      ))
  }

  get conversationID(): string {
    return this._conversationID
  }

  get files(): string[] | undefined {
    if (this._files) {
      return [...this._files]
    }

    return this._files
  }

  addFile(value: string) {
    if (this._files) {
      this._files.push(value)
      return
    }
    this._files = [value]
  }

  removeFile(value: string) {
    this._files && (this._files = this._files.filter((f) => f !== value))
  }
}

export class MessageQuery extends Query<Message> {}
