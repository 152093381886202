import { v4 as uuidv4 } from 'uuid'
import { Note } from './Note'

export interface NoteDTO {
  id: string | undefined
  description: string
  date: Date
  userID: string
}

export function emptyNoteDTO() {
  return {
    id: uuidv4(),
    description: '',
    date: new Date(),
    userID: '',
  }
}

export function fromModel(d: Note): NoteDTO {
  return {
    id: d.id,
    description: d.description,
    date: d.date,
    userID: d.userID,
  }
}

export function toModel(d: NoteDTO): Note {
  return new Note(d)
}
