import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { NotificationsConfig as NCG } from '../../features/notifications-config'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'

export function NotificationsConfig(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)

  return (
    <>
      <NCG />
    </>
  )
}
