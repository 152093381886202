import { RelatedUser } from './RelatedUser'

type related = {
  id: string
  kind: number
}

export interface RelatedUserDTO {
  relatedUsers: related[]
  userID: string
}

export function toModel(d: RelatedUserDTO): RelatedUser {
  return new RelatedUser(d)
}
