import { Query } from '../../../common/api/Query'
import { MediaDTO } from './MediaDTO'
export class Media {
  private readonly _id: string | undefined
  private readonly _data: string
  private _extension: string

  constructor(p: MediaDTO) {
    this._id = p.id
    this._data = p.data
    this._extension = p.extension
  }

  get id(): string | undefined {
    return this._id
  }

  get data(): string {
    return this._data
  }

  get extension(): string {
    return this._extension
  }

  set extension(value: string) {
    this._extension = value
  }
}

export class MediaQuery extends Query<Media> {}
