import React from 'react'
import { MobileMenu as M } from '../../features/mobile-menu'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { RouteProps } from '../../routes/AppRouter'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'

export function MobileMenu(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)

  return (
    <>
      <M />
    </>
  )
}
