export enum TypeOfRepeating {
  None = 0,
  Day = 1,
  Week,
  Month,
}

export const typeOfRepeatingTypes = (): Record<TypeOfRepeating, string> => ({
  [TypeOfRepeating.None]: 'None',
  [TypeOfRepeating.Day]: 'day',
  [TypeOfRepeating.Week]: 'week',
  [TypeOfRepeating.Month]: 'month',
})
