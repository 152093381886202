import { Container, IInit } from '../../../common/container/Container'
import { Contact } from '../models/Contact'
import { MessengerContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { ContactDTO, fromModel, toModel } from '../models/ContactDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface IContactApi extends IInit {
  getByID(id: string): Observable<Contact | undefined>

  getFilteredItems(q: Query<Contact>): Observable<Contact[]>

  add(e: Contact): Observable<Contact | undefined>

  update(e: Contact): Observable<Contact | undefined>

  delete(e: Contact): Observable<boolean>
}

export class ContactApi implements IContactApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as MessengerContainerConfig).moduleFullUrl + '/contacts'
  }

  add(e: Contact): Observable<Contact | undefined> {
    return this._httpClient
      .post<Contact>({ url: this._url, body: fromModel(e) })
      .pipe(
        map<ContactDTO, Contact>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(e: Contact): Observable<boolean> {
    return this._httpClient.delete({ url: this._url, body: fromModel(e) }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Contact | undefined> {
    return this._httpClient
      .get<Contact>({ url: `${this._url}/${id}` })
      .pipe(
        map<ContactDTO, Contact>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredItems(q: Query<Contact>): Observable<Contact[]> {
    return this._httpClient
      .get<Contact[]>({ url: this._url })
      .pipe(
        map<ContactDTO[], Contact[]>((dto) => dto.map((d) => toModel(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  update(e: Contact): Observable<Contact | undefined> {
    return this._httpClient
      .put<Contact>({ url: this._url, body: fromModel(e) })
      .pipe(
        map<ContactDTO, Contact>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
