import { Media } from './Media'
import { v4 as uuidv4 } from 'uuid'

export interface MediaDTO {
  id?: string
  data: string
  extension: string
}

export function emptyMediaDTO(creator: string | undefined): MediaDTO {
  return {
    id: '',
    data: '',
    extension: '',
  }
}

export function fromModel(f: Media): MediaDTO {
  return {
    id: f.id,
    data: f.data,
    extension: f.extension,
  }
}

export function toModel(d: MediaDTO): Media {
  return new Media(d)
}
