import { FormFieldType } from '../../modules/forms/enums/FormFieldType'

export function getStringValuesFromEnum(enumerable: any) {
  return Object.keys(enumerable).filter((k) => Number.isNaN(Number.parseInt(k)))
}

export function getStringValueFromEnum(enumerable: any, index: number) {
  return Object.keys(enumerable).filter((k) => Number.isNaN(Number.parseInt(k)))[index]
}

export function getNumericEnumLength(enumerable: any) {
  let length = Object.keys(enumerable).length / 2

  //If enums contains 0, the size is reduced
  if (Object.keys(enumerable).includes('0')) {
    return --length
  }

  return length
}

export function isSpecialType(type: FormFieldType) {
  const specialTypes = [FormFieldType.MultiSelect, FormFieldType.RadioButton, FormFieldType.Select]
  return specialTypes.includes(type)
}
