import React from 'react'
import { Box, TableBody, TableCell, TableRow, Skeleton } from '@mui/material'
import { Actions, Field } from './types'
import { GenericTooltip } from '../generic-tooltip'
import { useTranslation } from 'react-i18next'

export type BodyProps<T> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
  readonly isLoading?: boolean
}

export function BodyTreatment<T extends { [key: string]: any }>(props: BodyProps<T>) {
  const { t } = useTranslation()
  const innerWidth = window.innerWidth

  const styles = {
    body: {
      fontSize: '14px',
      fontFamily: 'Montserrat-Regular, sans-serif',
      borderBottom: '1px solid #D1D1D1',
      padding: '0 auto 0 auto',
      verticalAlign: 'initial',
      // width: '13.98vw',
    },
    icon: {
      verticalAlign: 'middle',
      cursor: 'pointer',
      padding: '0 4px',
    },
  }

  const rows = props.items.map((item, i) => {
    const actionStyle = props.actions?.styleFunc ? props.actions.styleFunc(item) : ''
    return !props.isLoading ? (
      <TableRow sx={styles.body} key={item[props.rowKeyField] + i}>
        {Object.values(props.fields).map((field, i) => {
          const styleFuncResult = field.styleFunc ? field.styleFunc(field, item) : ''
          const style = typeof styleFuncResult === 'object' ? styleFuncResult : {}
          return (
            <TableCell
              sx={{
                ...styles.body,
                ...style,
                width: `${100 / Object.keys(props.fields).length}%`,
                marginLeft: 4,
              }}
              key={(field.name as string) + i + 'cell'}
            >
              {field.renderFunc ? field.renderFunc(field, item) : item[field.name].toString()}
            </TableCell>
          )
        })}

        {props.actions && props.actions.items?.length > 0 && (
          <TableCell
            sx={{
              ...styles.body,
              width: `${100 / Object.keys(props.fields).length}%`,
              marginLeft: 4,
              // Ensure any additional styles are applied here if actionStyle is an object
              ...(typeof actionStyle === 'object' ? actionStyle : {}),
            }}
            key={item[props.rowKeyField] + 'cell'}
          >
            <Box
              key={item[props.rowKeyField] + 'box'}
              display="flex"
              justifyContent="space-between"
            >
              {props.actions.items.map((a, i) => {
                if (a.hidden && a.hidden(item)) {
                  return
                }
                return (
                  <GenericTooltip
                    values={[t(a.label || a.icon)]}
                    icon={
                      <img
                        src={a.icon}
                        style={{
                          verticalAlign: 'middle',
                          cursor: 'pointer',
                          padding: '0 4px',
                        }}
                        key={item[props.rowKeyField]}
                        onClick={() => a.handler(item)}
                      />
                    }
                    noUseFab={true}
                  />
                )
              })}
            </Box>
          </TableCell>
        )}
      </TableRow>
    ) : (
      <TableRow sx={styles.body} key={item[props.rowKeyField] + i}>
        {Object.values(props.fields).map((field, i) => {
          const styleFuncResult = field.styleFunc ? field.styleFunc(field, item) : ''
          const style = typeof styleFuncResult === 'object' ? styleFuncResult : {}
          return (
            <TableCell
              sx={{
                ...styles.body,
                ...style,
              }}
              key={(field.name as string) + i + 'cell'}
            >
              <Skeleton />
            </TableCell>
          )
        })}

        {props.actions && props.actions.items?.length > 0 && (
          <TableCell
            sx={{
              ...styles.body,
              // Ensure any additional styles are applied here if actionStyle is an object
              ...(typeof actionStyle === 'object' ? actionStyle : {}),
            }}
            key={item[props.rowKeyField] + i + 'cellq'}
          >
            <Box display="flex" justifyContent="space-between">
              {props.actions.items.map((a) => {
                if (a.hidden && a.hidden(item)) {
                  return
                }
                return (
                  <GenericTooltip
                    values={[t(a.label || a.icon)]}
                    icon={<Skeleton variant="circular" width={10} height={10} />}
                    noUseFab={true}
                  />
                )
              })}
            </Box>
          </TableCell>
        )}
      </TableRow>
    )
  })

  return (
    <TableBody style={{ overflow: 'auto', maxHeight: '450px', minHeight: '450px' }}>
      {rows}
    </TableBody>
  )
}
