import { File } from './File'
import { v4 as uuidv4 } from 'uuid'

export interface FileDTO {
  id?: string
  name: string
  description: string
  data: string
  size: number
  createdAt: Date
  mimeType: string
  extension: string
  ownerID: string
}

export function emptyFileDTO(creator: string | undefined): FileDTO {
  return {
    id: uuidv4(),
    data: '',
    name: '',
    description: '',
    extension: '',
    size: 0,
    createdAt: new Date(),
    mimeType: '',
    ownerID: creator || '',
  }
}

export function fromModel(f: File): FileDTO {
  return {
    id: f.id,
    data: f.data,
    name: f.name,
    description: f.description,
    extension: f.extension,
    size: f.size,
    createdAt: f.createdAt,
    mimeType: f.mimeType,
    ownerID: f.ownerID,
  }
}

export function toModel(d: FileDTO): File {
  return new File(d)
}
