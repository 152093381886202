import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { TableResults as FR } from '../../features/form-generator/TableResults'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import { useParams } from 'react-router-dom'

export function FormGeneratorResults(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()
  const { id } = useParams()

  setTitle(title)

  return (
    <>
      <FR id={id} />
    </>
  )
}
