import { Notification } from './Notification'
import { Query } from '../../../common/api/Query'
import { EventCategory } from '../enums/EventCategory'

export type EventProps = {
  id?: string
  title: string
  description: string
  startDate: Date
  finishDate: Date
  repeatingUntil?: Date
  repeating: string
  place: string
  idObject: string
  ownerID: string
  isOwnerBusy: boolean
  eventCategory: EventCategory
  users: string[]
  files?: string[]
  notifications?: Notification[]
}

export class Event {
  private readonly _id: string | undefined
  private readonly _title: string
  private readonly _description: string
  private _startDate: Date
  private _finishDate: Date
  private readonly _repeatingUntil: Date | undefined
  private readonly _repeating: string
  private readonly _place: string
  private readonly _idObject: string
  private readonly _ownerID: string
  private readonly _isOwnerBusy: boolean
  private _eventCategory: EventCategory

  private _users: string[]
  private _files: string[] | undefined
  private _notifications: Notification[] | undefined

  constructor(p: EventProps) {
    this._id = p.id
    this._title = p.title
    this._description = p.description
    this._startDate = new Date(p.startDate)
    this._finishDate = new Date(p.finishDate)
    this._repeatingUntil = p.repeatingUntil ? new Date(p.repeatingUntil) : undefined
    this._repeating = p.repeating
    this._place = p.place
    this._idObject = p.idObject
    this._ownerID = p.ownerID
    this._isOwnerBusy = p.isOwnerBusy
    this._eventCategory = p.eventCategory
    this._users = p.users || []
    this._files = p.files || []
    this._notifications = p.notifications
  }

  get id(): string | undefined {
    return this._id
  }

  get title(): string {
    return this._title
  }

  get description(): string {
    return this._description
  }

  get startDate(): Date {
    return this._startDate
  }

  get finishDate(): Date {
    return this._finishDate
  }

  get repeatingUntil(): Date | undefined {
    return this._repeatingUntil
  }

  get repeating(): string {
    return this._repeating
  }

  get place(): string {
    return this._place
  }

  get idObject(): string {
    return this._idObject
  }

  get ownerID(): string {
    return this._ownerID
  }

  get isOwnerBusy(): boolean {
    return this._isOwnerBusy
  }

  get eventCategory(): EventCategory {
    return this._eventCategory
  }

  set eventCategory(value: EventCategory) {
    this._eventCategory = value
  }

  get users(): string[] {
    return [...this._users]
  }

  set startDate(value: Date) {
    this._startDate = new Date(value.getTime())
  }
  set finishDate(value: Date) {
    this._finishDate = new Date(value.getTime())
  }

  addUser(value: string) {
    if (this._users) {
      this._users.push(value)
    } else {
      this._users = [value]
    }
  }

  removeUser(value: string) {
    if (this._users) {
      this._users = this._users.filter((u) => u !== value)
    }
  }

  get files(): string[] | undefined {
    if (this._files) {
      return [...this._files]
    }

    return this._files
  }

  addFile(value: string) {
    if (this._files) {
      this._files.push(value)
    } else {
      this._files = [value]
    }
  }

  removeFile(value: string) {
    if (this._files) {
      this._files = this._files.filter((f) => f !== value)
    }
  }

  get notifications(): Notification[] | undefined {
    if (this._notifications) {
      return [...this._notifications]
    }

    return this._notifications
  }

  addNotification(value: Notification) {
    if (this._notifications) {
      this._notifications.push(value)
    } else {
      this._notifications = [value]
    }
  }

  removeNotification(value: Notification) {
    if (this._notifications) {
      this._notifications = this._notifications.filter(
        (n) => n.notifyAt === value.notifyAt && n.transportType !== value.transportType
      )
    }
  }
}

export class EventQuery extends Query<Event> {}
