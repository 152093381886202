import { ArticleDTO } from './ArticleDTO'

export class Article {
  private readonly _id: string | undefined
  private readonly _createdAt: Date
  private readonly _creatorID: string

  private _title: string
  private _content: string
  private _updatedAt: Date

  constructor(p: ArticleDTO) {
    this._id = p.id
    this._title = p.title
    this._content = p.content
    this._createdAt = p.createdAt
    this._updatedAt = p.updatedAt
    this._creatorID = p.creatorID
  }

  get id(): string | undefined {
    return this._id
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get creatorID(): string {
    return this._creatorID
  }

  get title(): string {
    return this._title
  }

  get content(): string {
    return this._content
  }

  get updatedAt(): Date {
    return this._updatedAt
  }

  set title(value: string) {
    this._title = value
  }

  set content(value: string) {
    this._content = value
  }

  set updatedAt(value: Date) {
    this._updatedAt = value
  }
}
