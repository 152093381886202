export enum ConversationStatus {
  New = 1,
  Open,
  Closed,
}

export const conversationStatuses = (): Record<ConversationStatus, string> => ({
  [ConversationStatus.New]: 'New',
  [ConversationStatus.Open]: 'Open',
  [ConversationStatus.Closed]: 'Closed',
})
