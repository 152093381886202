import { EditorFieldsItem } from './EditorFieldsItem'
import React, { useEffect, useState } from 'react'
import { emptyFormFieldDTO, FormFieldDTO } from '../../modules/forms/models/FormDTO'
import { Box } from '@mui/material'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import style from './EditorField.module.css'

type FormFieldListProps = {
  formFields: FormFieldDTO[]
  handlerChange: (formFields: FormFieldDTO[]) => void
}

export function EditorFields(props: FormFieldListProps) {
  const { t } = useTranslation()

  const [formFields, setFormFields] = useState<FormFieldDTO[]>([])

  useEffect(() => {
    setFormFields(props.formFields)
  }, [props.formFields])

  const addFormField = () =>
    setFormFields([...formFields, emptyFormFieldDTO(formFields.length + 1)])

  function handleFormFieldChange(formField: FormFieldDTO) {
    const index = formFields.findIndex((ff) => ff.id === formField.id)
    if (index > -1) {
      const result = [...formFields]
      result.splice(index, 1, formField)
      setFormFields(result)
    }
  }

  const handleFormFieldRemove = (id: string) => setFormFields(formFields.filter((f) => f.id !== id))

  useEffect(() => {
    props.handlerChange(formFields)
  }, [formFields])

  return (
    <>
      {formFields.length > 0 && <p className={style.sectionLabel}>{t('formFields')}</p>}
      {formFields.map((f: FormFieldDTO) => (
        <Box my={3}>
          <EditorFieldsItem
            key={f.id}
            handleChange={handleFormFieldChange}
            handleRemove={handleFormFieldRemove}
            formField={f}
          />
        </Box>
      ))}
      <Box display="flex" alignItems="center" mt={3}>
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'button'}
          label={t('addFormField')}
          handler={addFormField}
        />
      </Box>
    </>
  )
}
