import { Container } from '../../common/container/Container'

export type ContentContainerConfig = {
  moduleFullUrl: string
}

export type ContentProps = {
  parentContainer: Container
  config: ContentContainerConfig
}

export const STATISTICS_MODULE = Symbol('STATISTICS_MODULE')
export const WEARABLE_MODULE = Symbol('WEARABLE_MODULE')
export const INTENSITY_MODULE = Symbol('INTENSITY_MODULE')

export const USER_CALORIES_API_KEY = Symbol('USER_CALORIES_API_KEY')
export const USER_HEART_API_KEY = Symbol('USER_HEART_API_KEY')
export const USER_INTENSITY_API_KEY = Symbol('USER_INTENSITY_API_KEY')
export const USER_SLEEP_API_KEY = Symbol('USER_SLEEP_API_KEY')
export const USER_SPO2_API_KEY = Symbol('USER_SPO2_API_KEY')
export const USER_STEPS_API_KEY = Symbol('USER_STEPS_API_KEY')

export const WEARABLE_API_KEY = Symbol('WEARABLE_API_KEY')

export const USER_CALORIES_SERVICE_KEY = Symbol('USER_CALORIES_SERVICE_KEY ')
export const USER_HEART_SERVICE_KEY = Symbol('USER_HEART_SERVICE_KEY ')
export const USER_INTENSITY_SERVICE_KEY = Symbol('USER_INTENSITY_SERVICE_KEY')
export const USER_SLEEP_SERVICE_KEY = Symbol('USER_SLEEP_SERVICE_KEY')
export const USER_SPO2_SERVICE_KEY = Symbol('USER_SPO2_SERVICE_KEY')
export const USER_STEPS_SERVICE_KEY = Symbol('USER_STEPS_SERVICE_KEY')

export const WEARABLE_SERVICE_KEY = Symbol('WEARABLE_SERVICE_KEY ')
