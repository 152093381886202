import { Wearable } from '../models/Wearable'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { WearableDTO } from '../models/WearableDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { IWearableApi } from '../api/WearableApi'

type Props = {
  apiKey: symbol
}

export interface IWearableService extends IInit {
  getByID(id: string): Observable<Wearable | undefined>

  getFilteredList(q: Query<Wearable>): Observable<ItemList<Wearable>>

  add(e: WearableDTO): Observable<Wearable | undefined>

  update(e: WearableDTO): Observable<Wearable | undefined>

  delete(id: string): Observable<boolean>
}

export class WearableService implements IWearableService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IWearableApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IWearableApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: WearableDTO): Observable<Wearable | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Wearable | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<Wearable>): Observable<ItemList<Wearable>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: WearableDTO): Observable<Wearable | undefined> {
    return this._api.update(e)
  }
}
