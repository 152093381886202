import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { IExerciseAlgorithmApi } from '../api/ExerciseAlgorithm'

type Props = {
  apiKey: symbol
}

export interface IExerciseAlgorithmService extends IInit {
  generateExercises(id: string, startDate: Date, finishDate: Date): void
}

export class ExerciseAlgorithmService implements IExerciseAlgorithmService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IExerciseAlgorithmApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IExerciseAlgorithmApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  generateExercises(userId: string, startDate: Date, finishDate: Date): void {
    return this._api.generateExercises(userId, startDate, finishDate)
  }
}
