import { Intensity } from './Intensity'
import { v4 as uuidv4 } from 'uuid'

export interface IntensityDTO {
  id: string
  sedentary: number
  lightly_active: number
  active: number
  very_active: number
  userId: string
  date: Date
}

export function emptyIntensityDTO(): IntensityDTO {
  return {
    id: uuidv4(),
    sedentary: 0,
    lightly_active: 0,
    active: 0,
    very_active: 0,
    userId: '',
    date: new Date(),
  }
}

export function fromModel(t: Intensity): IntensityDTO {
  return {
    id: t.id,
    sedentary: t.sedentary,
    lightly_active: t.lightly_active,
    active: t.active,
    very_active: t.very_active,
    userId: t.userId,
    date: t.date,
  }
}

export function toModel(d: IntensityDTO): Intensity {
  return new Intensity(d)
}
