import { AddFile } from '../../features/files/AddFile'
import { ListAddedFiles } from '../../features/files/ListAddedFiles'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { File as F } from '../../modules/files/models/File'
import { Query, QueryParam } from '../../common/api/Query'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { LoadingFile } from '../loading-spinner/LoadingSpinner'

const filesContainer = getFileContainer()
const filesService = filesContainer.get<FileService>(FILE_SERVICE_KEY)

export type FileItemProps = {
  key: string
  label?: string
  field: string
  filesID: string[]
  userID: string
  cleanFiles: boolean
  handleFiles: (field: string, files: F[]) => void
  handlerChangeAttachFile?: (event: ChangeEvent<HTMLInputElement>) => void
  handlerRemoveFile?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
  uploadRemoveFileDirectly?: boolean
  maxFiles?: number
  disabled?: boolean
  destinationFolder?: string
  eventID?: string
  defaultFile?: F[]
}

export function FileItem(props: FileItemProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    if (!isLoading || props.filesID?.length === 0) {
      setIsLoading(false)
      return
    }
    filesService
      .getFilteredItems(
        new Query({
          query: [new QueryParam<F>('id', props.filesID)],
        })
      )
      .subscribe((_) => {
        setIsLoading(false)
      })
  }, [isLoading])

  useEffect(() => {
    if (isSending) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0
          }
          const diff = Math.random() * 10
          return Math.min(oldProgress + diff, 100)
        })
      }, 500)

      //return () => {
      clearInterval(timer)
      //}
    }
  }, [])
  /*

  async function handlerChangeAttachFile(event: ChangeEvent<HTMLInputElement>) {
    setIsSending(true)
    if (props.destinationFolder == 'Event') {
      const fs = await createFiles(
        event,
        props.userID,
        props.uploadRemoveFileDirectly || false,
        props.destinationFolder || '',
        null,
        null,
        props.eventID ? props.eventID : null,
        true,
        false
      ).finally(() => {
        setIsSending(false)
      })
      props.handleFiles(props.field, [...(props.defaultFile || []), ...fs])
    } else if (props.destinationFolder == 'Article') {
      const fs = await createFiles(
        event,
        props.userID,
        props.uploadRemoveFileDirectly || false,
        props.destinationFolder || '',
        props.eventID ? props.eventID : null,
        null,
        null,
        false,
        false
      ).finally(() => {
        setIsSending(false)
      })
      props.handleFiles(props.field, [...(props.defaultFile || []), ...fs])
    } else {
      const fs = await createFiles(
        event,
        props.userID,
        props.uploadRemoveFileDirectly || false,
        props.destinationFolder || '',
        null,
        null,
        null,
        true,
        false
      ).finally(() => {
        setIsSending(false)
      })

      props.handleFiles(props.field, [...(props.defaultFile || []), ...fs])
    }
  }

  useEffect(() => {
    if (props.cleanFiles) {
      props.handleFiles(props.field, [])
    }
  }, [props.cleanFiles])

  async function handlerRemoveFile(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    if (props.defaultFile) {
      const fs = await removeFile(event, props.defaultFile, props.uploadRemoveFileDirectly || false)
      props.handleFiles('Remove', fs)
    }
  }
*/

  return (
    <>
      {isSending ? (
        <>
          <LoadingFile progress={progress} />
        </>
      ) : (
        props.defaultFile && (
          <>
            {props.label ? (
              <AddFile
                handlerChangeAttachFile={() => {}}
                lengthFiles={props.defaultFile.length}
                buttonLabel={props.label}
                maxFiles={props.maxFiles || 4}
                disabled={props.disabled || false}
              />
            ) : (
              <AddFile
                handlerChangeAttachFile={() => {}}
                lengthFiles={props.defaultFile.length}
                maxFiles={props.maxFiles || 4}
                disabled={props.disabled || false}
              />
            )}

            <ListAddedFiles files={props.defaultFile || []} handlerClickRemoveFile={() => {}} />
          </>
        )
      )}
    </>
  )
}
