import { Media } from '../models/Media'
import { Container, IInit } from '../../../common/container/Container'
import { MediaContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { MediaDTO, toModel } from '../models/MediaDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from 'common/models/ItemList'

export interface IMediaApi extends IInit {
  getByName(id: string | null): Observable<Media | undefined>

  getFilteredItems(q: Query<Media>): Observable<ItemList<Media>>
}

export class MediaApi implements IMediaApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as MediaContainerConfig).moduleFullUrl
  }

  getByName(id: string | null): Observable<Media | undefined> {
    return this._httpClient
      .get<Media>({ url: this._url + '/' + id })
      .pipe(
        map<MediaDTO, Media>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredItems(q: Query<Media>): Observable<ItemList<Media>> {
    return this._httpClient
      .get<ItemList<Media>>({ url: prepareURL(this._url, q) })
      .pipe(
        map<ItemList<MediaDTO>, ItemList<Media>>((dto) => {
          const itemList = emptyList<Media>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Media>())
        })
      )
  }
}
