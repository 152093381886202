import { Box } from '@mui/material'
import style from './Modal.module.css'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Header } from '../../components/header/Header'
import close from '../../assets/articles/delete.svg'
import { Event as E } from '../../modules/calendar/models/Event'
import { dateToDateString, dateToTimeWithoutSecondsString } from '../../common/utils/date'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { viewFile } from '../../common/utils/file'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { Link } from 'react-router-dom'
import { forkJoin } from 'rxjs'
import { Observable } from 'rxjs'
import { User } from '../../modules/users/models/User'
import { File as F } from '../../modules/files/models/File'
import { EventCategory, eventCategoryTypes } from '../../modules/calendar/enums/EventCategory'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)
const eventCategories = eventCategoryTypes()

type CalendarModalProps = {
  handleClose: () => void
  handleEdit: (e: E) => void
  handleRemove: (e: E) => void
  event: E
}

export const CalendarModal = (props: CalendarModalProps) => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()

  const [users, setUsers] = useState<string[]>([])
  const [nameFiles, setNameFiles] = useState<Map<string, string>>(new Map())

  useEffect(() => {
    if (!props.event.users) return

    getUsers(props.event.users).subscribe((ul) => {
      //TODO filter is necessary for TheSystem user
      setUsers(ul.filter((u: User) => u).map((u: User) => u.firstName))
    })
    if (props.event.files) {
      getFiles(props.event.files).subscribe((fl: F[]) => {
        const tmpNameFiles = new Map()
        fl.forEach((f) => {
          tmpNameFiles.set(f.id || '', f.name)
        })
        setNameFiles(tmpNameFiles)
      })
    }
  }, [])

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((uid) => userService.getByID(uid)) as unknown) as Observable<User[]>

  const getFiles = (ids: string[]): Observable<F[]> =>
    forkJoin(ids.map((uid) => filesService.getByID(uid))) as Observable<F[]>

  const hours = `${dateToTimeWithoutSecondsString(props.event.startDate)} -
    ${dateToTimeWithoutSecondsString(props.event.finishDate)}`

  const date = `${dateToDateString(props.event.startDate)} -
    ${dateToDateString(props.event.finishDate)}`

  const startDate = dateToDateString(props.event.startDate)

  const isOwner = (e: E) => {
    console.log(e.ownerID)
    if (loggedUser?.id === e.ownerID) return true
  }

  const viewResource = (id: string) =>
    filesService.getByID(id).subscribe((res) => res && viewFile(res))

  const getCategoryName = (n: number) => {
    const array = Array(Object.values(eventCategories))
    return array[0][n - 1]
  }

  return (
    <>
      <Box className={style.modalView}>
        <Header label={t('eventInformation')} icon={close} onClick={props.handleClose} />
        <Box mt={1} mb={1} display={'flex'} justifyContent={'left'}>
          <p className={style.titleLabel}>{t('title')}</p>
          <p className={style.label}>{t(props.event.title)}</p>
        </Box>
        <Box mt={1} mb={1} display={'flex'} justifyContent={'left'}>
          <p className={style.titleLabel}>{t('description')}</p>
          <p className={style.description}>{t(props.event.description)}</p>
        </Box>
        {/* <Box mt={1} mb={1} display={'flex'} justifyContent={'left'}>
          <p className={style.titleLabel}>{t('eventCategory')}</p>
          <p className={style.label}>{getCategoryName(props.event.eventCategory)}</p>
        </Box>*/}
        <Box mt={1} mb={1} display={'flex'} justifyContent={'left'}>
          <p className={style.titleLabel}>{t('date')}</p>
          <p className={style.label}>
            {props.event.eventCategory === EventCategory.Training
              ? date
              : `${startDate} - ${hours}`}
          </p>
        </Box>
        {props.event.place ? (
          <Box mt={1} mb={1} display={'flex'} justifyContent={'left'}>
            <p className={style.titleLabel}>{t('place')}</p>
            <p className={style.label}>{props.event.place}</p>
          </Box>
        ) : (
          ''
        )}
        {/*{users.length && (
          <Box mt={1} mb={1} display={'flex'} justifyContent={'left'}>
            <p className={style.titleLabel}>{t('participants')}</p>
            {users.map((u) => (
              <Chip key={u} className={style.chip} label={u} />
            ))}
          </Box>
        )}*/}
        {props.event.files?.length ? (
          <Box mt={1} mb={1} display={'flex'} justifyContent={'left'}>
            <p className={style.titleLabel}>{t('files')}</p>
            {props.event.files.map((f) => (
              <Link key={f} to={'.'} onClick={() => viewResource(f)}>
                <p className={style.titleLabel}>{nameFiles.get(f)}</p>
              </Link>
            ))}
          </Box>
        ) : null}
        {isOwner(props.event) ? (
          <Box mt={0} mb={0} display={'flex'} justifyContent={'center'} width={'100%'}>
            <Box mt={1} mb={1} display={'flex'} justifyContent={'center'} width={'100%'}>
              <Box mr={2}>
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('removeEvent')}
                  handler={() => props.handleRemove(props.event)}
                />
              </Box>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('edit')}
                handler={() => props.handleEdit(props.event)}
              />
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  )
}
