import { Container } from '../../common/container/Container'

export type ContentContainerConfig = {
  moduleFullUrl: string
}

export type ContentProps = {
  parentContainer: Container
  config: ContentContainerConfig
}

export const TRAINING_MODULE = Symbol('TRAINING_MODULE')

export const TRAINING_API_KEY = Symbol('TRAINING_API_KEY')
export const USER_TRAINING_API_KEY = Symbol('USER_TRAINING_API_KEY')
export const EXERCISEALGORITHM_API_KEY = Symbol('EXERCISEALGORITHM_API_KEY')

export const TRAINING_SERVICE_KEY = Symbol('TRAINING_SERVICE_KEY')
export const USER_TRAINING_SERVICE_KEY = Symbol('USER_TRAINING_SERVICE_KEY')
export const EXERCISEALGORITHM_SERVICE_KEY = Symbol('EXERCISEALGORITHM_SERVICE_KEY')
