import React from 'react'
import Avatar1 from '../../assets/images/avatar-1.jpg'

import styles from '../header/Header.module.css'

const friend = (props) => {
  let timeClass = ['d-block']
  if (props.data.status) {
    timeClass = [...timeClass, styles.timeChat]
  } else {
    timeClass = [...timeClass, 'text-muted']
  }

  let time = ''
  if (props.data.time) {
    time = <small className={timeClass.join(' ')}>{props.data.time}</small>
  }

  let newFriend = ''
  if (props.data.new) {
    newFriend = <div className={styles.chatNew}>{props.data.new}</div>
  }

  return (
    <>
      <div
        className={
          props.activeId === props.data.id
            ? 'media userlist-box ripple active'
            : 'media userlist-box ripple'
        }
        onClick={props.clicked}>
        <a className="media-left">
          {' '}
          <img className="media-object img-radius" src={Avatar1} alt={props.data.name} />
          {newFriend}
        </a>
        <div className="media-body">
          <h6 className="chat-header">
            {props.data.name}
            {time}
          </h6>
        </div>
      </div>
    </>
  )
}

export default friend
