import { Media } from '../models/Media'
import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IMediaApi } from '../api/MediaApi'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'

type Props = {
  apiKey: symbol
}

export interface IMediaService extends IInit {
  getByName(id: string): Observable<Media | undefined>

  getFilteredItems(q: Query<Media>): Observable<ItemList<Media>>
}

export class MediaService implements IMediaService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IMediaApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IMediaApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByName(id: string | null): Observable<Media | undefined> {
    return this._api.getByName(id)
  }

  getFilteredItems(q: Query<Media>): Observable<ItemList<Media>> {
    return this._api.getFilteredItems(q).pipe()
  }
}
