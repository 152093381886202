import { IntensityDTO } from './IntensityDTO'

export class Intensity {
  private _id: string
  private _sedentary: number
  private _lightly_active: number
  private _active: number
  private _very_active: number
  private _userId: string
  private _date: Date

  constructor(p: IntensityDTO) {
    this._id = p.id
    this._sedentary = p.sedentary
    this._lightly_active = p.lightly_active
    this._active = p.active
    this._very_active = p.very_active
    this._userId = p.userId
    this._date = p.date
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get sedentary(): number {
    return this._sedentary
  }

  set sedentary(sedentary: number) {
    this._sedentary = sedentary
  }

  get lightly_active(): number {
    return this._lightly_active
  }

  set lightly_active(lightly_active: number) {
    this._lightly_active = lightly_active
  }

  get active(): number {
    return this._active
  }

  set active(value: number) {
    this._active = value
  }

  get very_active(): number {
    return this._very_active
  }

  set very_active(value: number) {
    this._very_active = value
  }

  get userId(): string {
    return this._userId
  }

  set userId(value: string) {
    this._userId = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }
}

export interface StatisticsQuery {
  userId: string
}
