import { Container } from '../../common/container/Container'

export type MediaContainerConfig = {
  moduleFullUrl: string
}

export type MediaProps = {
  parentContainer: Container
  config: MediaContainerConfig
}

export const MEDIA_MODULE = Symbol('MEDIA_MODULE')

export const MEDIA_API_KEY = Symbol('MEDIA_API_KEY')

export const MEDIA_SERVICE_KEY = Symbol('MEDIA_SERVICE_KEY')
