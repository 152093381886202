import { Container, IInit } from '../../../common/container/Container'
import { Note } from '../models/Note'
import { CalendarContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { NoteDTO, toModel } from '../models/NoteDTO'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { prepareURL } from '../../../common/api/http-helpers'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface INoteApi extends IInit {
  getByID(id: string): Observable<Note | undefined>

  getFilteredList(q: Query<Note>, userId: string): Observable<ItemList<Note>>

  add(e: NoteDTO): Observable<Note | undefined>

  update(e: NoteDTO): Observable<Note | undefined>

  delete(id: string): Observable<boolean>
}

export class NoteApi implements INoteApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as CalendarContainerConfig).moduleFullUrl + '/notes'
  }

  add(e: NoteDTO): Observable<Note | undefined> {
    return this._httpClient
      .post<Note>({ url: this._url, body: e })
      .pipe(
        map<NoteDTO, Note>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Note | undefined> {
    return this._httpClient
      .get<Note>({ url: `${this._url}/${id}` })
      .pipe(
        map<NoteDTO, Note>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredList(q: Query<Note>, userId: string): Observable<ItemList<Note>> {
    return this._httpClient
      .get<ItemList<Note>>({ url: prepareURL(`${this._url}/${userId}/list`, q) })
      .pipe(
        map<ItemList<NoteDTO>, ItemList<Note>>((dto) => {
          const itemList = emptyList<Note>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Note>())
        })
      )
  }

  update(e: NoteDTO): Observable<Note | undefined> {
    return this._httpClient
      .put<Note>({ url: this._url, body: e })
      .pipe(
        map<NoteDTO, Note>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
