import { Container } from '../../common/container/Container'

export type ContentContainerConfig = {
  moduleFullUrl: string
}

export type ContentProps = {
  parentContainer: Container
  config: ContentContainerConfig
}

export const PATOLOGY_MODULE = Symbol('PATOLOGY_MODULE')

export const PATOLOGY_API_KEY = Symbol('PATOLOGY_API_KEY')

export const PATOLOGY_SERVICE_KEY = Symbol('PATOLOGY_SERVICE_KEY')
