import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { RolesService } from '../../modules/users/services/RolesServices'
import { getUserContainer } from '../../container/user-module'
import { ROLES_SERVICE_KEY } from '../../modules/users'
import { Role } from 'modules/users/models/Role'
import { PERMISSIONS } from './permissions'
import { camelize } from '../../common/utils/strings'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'

type RolePermissionsProps = {
  id: string
}

const roleContainer = getUserContainer()
const roleService = roleContainer.get<RolesService>(ROLES_SERVICE_KEY)

export function RolePermissions(props: RolePermissionsProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [submitMessage, setSubmitMessage] = useState<string>('')
  const [roles, setRoles] = useState<Role[]>([])
  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    if (isLoading) {
      roleService.getAll().subscribe((res) => {
        res.map((role) => {
          const newRole = {
            id: role.id,
            name: role.name,
            description: role.description,
            hasNewPermissions: role.hasNewPermissions,
            permissions: role.permissions,
          } as Role
          setRoles((roles) => [...roles, newRole])
        })
        setIsLoading(false)
      })
    }
  }, [isLoading])

  const handleCheck = (role: string, permission: string) => {
    roles.map((r) => {
      if (r.name === role) {
        if (r.permissions.includes(permission)) {
          const index = r.permissions.indexOf(permission)
          if (index > -1) {
            r.permissions.splice(index, 1)
            setChecked(!checked)
          }
        } else {
          r.permissions.push(permission)
          if (r.permissions.includes('')) r.permissions.shift()
          setChecked(!checked)
        }
      }
    })
  }

  const handleSubmit = () => {
    roles.map((role) => {
      role.permissions.forEach((p, i) => {
        if (p === '') role.permissions.splice(i, 1)
      })
      role.hasNewPermissions = true
      roleService.update(role).subscribe(() => console.log('ok'))
    })
    setSubmitMessage(t('TheChangesToPermissionsWasSaved'))
  }

  return (
    <>
      {!isLoading ? (
        <Box>
          <Table>
            <TableHead>
              <TableRow key={'Roles'}>
                <TableCell align="left">{t('Permissions')}</TableCell>
                {roles.map((role, index) => (
                  <TableCell key={index} align="center">
                    {t(camelize(role.name))}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {PERMISSIONS.map((permission, index) => (
                <TableRow key={index}>
                  <TableCell variant="head" scope="row">
                    {t(permission)}
                  </TableCell>
                  {roles.map((role) => (
                    <TableCell key={role.name + permission} align="center">
                      <Checkbox
                        checked={role.permissions.includes(permission)}
                        onChange={() => handleCheck(role.name, permission)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t('save')}
            handler={handleSubmit}
          />
          {' ' + submitMessage}
        </Box>
      ) : (
        ''
      )}
    </>
  )
}
