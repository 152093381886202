import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { NewsWall as N } from '../../features/newsWall'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import { Box } from '@mui/material'
import logoCareme from '../../assets/caremePlusLogo.svg'
import style from '../layout/Main.module.css'
import { useLocation, useParams } from 'react-router-dom'

type NewsWallProps = {
  id?: string
  selectedDate?: Date
} & RouteProps

export function NewsWall(props: NewsWallProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()
  const location = useLocation()

  setTitle(title)

  const state: NewsWallProps = location?.state as NewsWallProps

  return (
    <>
      <N selectedDate={state?.selectedDate} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <img src={logoCareme} alt="Logo Careme" className={style.logo} />
      </Box>
    </>
  )
}
