import React, { useEffect, useState } from 'react'
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  BarElement,
  PointElement,
  ScriptableContext,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { DateFilter } from '../../features/statistics/StatisticsModal'
interface StackedBarChartProps {
  titulo: string
  data: {
    id: string
    userId: string
    sedentary: number
    lightly_active: number
    active: number
    very_active: number
    date: Date
  }[]
  dataSetsNames: string[]
  eje: boolean
  filterDate: DateFilter
}

const StackedBarChart = (props: StackedBarChartProps): JSX.Element => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  )

  var labels: string[] = ['', '', '', '', '', '', '']

  let sedentary: number[] = []
  let lightly_active: number[] = []
  let active: number[] = []
  let very_active: number[] = []
  let date: string[] = []
  props.data.forEach((element) => {
    sedentary.push(element.sedentary)
    lightly_active.push(element.lightly_active)
    active.push(element.active)
    very_active.push(element.very_active)
    date.push(new Date(element.date).toLocaleDateString())
  })

  const [filterDate1, setFilterDate1] = useState<number[]>(sedentary.slice(0, 0))
  const [filterDate2, setFilterDate2] = useState<number[]>(lightly_active.slice(0, 0))
  const [filterDate3, setFilterDate3] = useState<number[]>(active.slice(0, 0))
  const [filterDate4, setFilterDate4] = useState<number[]>(very_active.slice(0, 0))
  const [filterLabels, setFilterLabels] = useState<string[]>(date.slice(0, 0))

  const filterValueWeek = [
    sedentary.slice(sedentary.length - 7, sedentary.length),
    lightly_active.slice(lightly_active.length - 7, lightly_active.length),
    active.slice(active.length - 7, active.length),
    very_active.slice(very_active.length - 7, very_active.length),
  ]

  useEffect(() => {
    let auxFirstDate = 0
    let auxFinishDate = 0
    let n = 0
    if (props.eje) {
      date.forEach((element, index) => {
        if (element == props.filterDate.startDate.toLocaleDateString()) {
          auxFirstDate = index
        }
        if (
          element == props.filterDate.finishDate.toLocaleDateString() ||
          (index + 1 == props.data.length && n == 0)
        ) {
          auxFinishDate = index
          n++
        }
      })
    }

    setFilterDate1(sedentary.slice(auxFirstDate, auxFinishDate + 1))
    setFilterDate2(lightly_active.slice(auxFirstDate, auxFinishDate + 1))
    setFilterDate3(active.slice(auxFirstDate, auxFinishDate + 1))
    setFilterDate4(very_active.slice(auxFirstDate, auxFinishDate + 1))
    setFilterLabels(date.slice(auxFirstDate, auxFinishDate + 1))
  }, [props.filterDate.startDate, props.filterDate.finishDate])

  const colors = [
    'rgba(206, 224, 62, 1)',
    'rgba(190,62,185, 1)',
    'rgba(196,104,255, 1)',
    'rgba(25,185,187, 1)',
  ] //Color donde empieza el degradado
  const colorsGradient = [
    'rgba(41, 160, 169, 1)',
    'rgba(190,62,185, 1)',
    'rgba(196,104,255, 1)',
    'rgba(25,185,187, 1)',
  ] //color degradado del principal

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: props.eje,
      },
      title: {
        display: props.eje,
        text: props.titulo,
      },
    },

    scales: {
      x: {
        stacked: true,
        display: props.eje,
      },
      y: {
        stacked: true,
        display: props.eje,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  }

  var datasets: any[] = []
  const data = () => {
    filterValueWeek.forEach((element, index) => {
      datasets.push({
        label: props.dataSetsNames[index],
        data: element,
        borderColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx
          const gradient = ctx.createLinearGradient(0, 50, 0, 400)
          gradient.addColorStop(0, colors[index])
          gradient.addColorStop(1, colorsGradient[index])
          return gradient
        },
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx
          const gradient = ctx.createLinearGradient(0, 50, 0, 400)
          gradient.addColorStop(0, colors[index])
          gradient.addColorStop(1, colorsGradient[index])
          return gradient
        },
        fill: true,
      })
    })

    return {
      labels,
      datasets,
    }
  }

  const datasetsModal: any[] = []
  const dataModal = () => {
    new Array(filterDate1, filterDate2, filterDate3, filterDate4).forEach((element, index) => {
      datasetsModal.push({
        label: props.dataSetsNames[index],
        data: element,
        borderColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx
          const gradient = ctx.createLinearGradient(0, 50, 0, 400)
          gradient.addColorStop(0, colors[index])
          gradient.addColorStop(1, colorsGradient[index])
          return gradient
        },
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx
          const gradient = ctx.createLinearGradient(0, 50, 0, 400)
          gradient.addColorStop(0, colors[index])
          gradient.addColorStop(1, colorsGradient[index])
          return gradient
        },
        fill: true,
      })
    })
    labels = filterLabels
    datasets = datasetsModal
    return {
      labels,
      datasets,
    }
  }

  return (
    <>
      <Bar options={options} data={props.eje ? dataModal() : data()} />
      {/*<Bar options={options} data={data()} />*/}
    </>
  )
}
export default StackedBarChart
