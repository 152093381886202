import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  STATISTICS_MODULE,
  WEARABLE_MODULE,
  INTENSITY_MODULE,
  USER_CALORIES_API_KEY,
  USER_HEART_API_KEY,
  WEARABLE_API_KEY,
  USER_INTENSITY_API_KEY,
  USER_SLEEP_API_KEY,
  USER_SPO2_API_KEY,
  USER_STEPS_API_KEY,
  USER_CALORIES_SERVICE_KEY,
  USER_HEART_SERVICE_KEY,
  WEARABLE_SERVICE_KEY,
  USER_INTENSITY_SERVICE_KEY,
  USER_SLEEP_SERVICE_KEY,
  USER_SPO2_SERVICE_KEY,
  USER_STEPS_SERVICE_KEY,
} from '../modules/statistics'
import { ContentProps } from 'modules/statistics/container'
import { UserCaloriesApi } from '../modules/statistics/api/UserCaloriesApi'
import { UserHeartApi } from '../modules/statistics/api/UserHeartApi'
import { UserCaloriesService } from '../modules/statistics/services/UserCaloriesService'
import { UserHeartService } from '../modules/statistics/services/UserHeartService'
import { UserStepsApi } from '../modules/statistics/api/UserStepsApi'
import { UserStepsService } from '../modules/statistics/services/UserStepsService'
import { UserSleepApi } from '../modules/statistics/api/UserSleepApi'
import { UserSleepService } from '../modules/statistics/services/UserSleepService'
import { UserSpO2Service } from '../modules/statistics/services/UserSpO2Service'
import { UserSpO2Api } from '../modules/statistics/api/UserSpO2Api'
import { UserIntensityService } from '../modules/statistics/services/UserIntensityService'
import { UserIntensityApi } from '../modules/statistics/api/UserIntensityApi'
import { WearableService } from '../modules/statistics/services/WearableService'
import { WearableApi } from '../modules/statistics/api/WearableApi'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(USER_CALORIES_API_KEY, new ContainerItem({ instance: new UserCaloriesApi() }))
  items.set(USER_HEART_API_KEY, new ContainerItem({ instance: new UserHeartApi() }))
  items.set(USER_STEPS_API_KEY, new ContainerItem({ instance: new UserStepsApi() }))
  items.set(USER_SLEEP_API_KEY, new ContainerItem({ instance: new UserSleepApi() }))
  items.set(USER_SPO2_API_KEY, new ContainerItem({ instance: new UserSpO2Api() }))
  items.set(USER_INTENSITY_API_KEY, new ContainerItem({ instance: new UserIntensityApi() }))
  items.set(WEARABLE_API_KEY, new ContainerItem({ instance: new WearableApi() }))

  items.set(
    USER_CALORIES_SERVICE_KEY,
    new ContainerItem({ instance: new UserCaloriesService({ apiKey: USER_CALORIES_API_KEY }) })
  )

  items.set(
    USER_HEART_SERVICE_KEY,
    new ContainerItem({ instance: new UserHeartService({ apiKey: USER_HEART_API_KEY }) })
  )

  items.set(
    USER_STEPS_SERVICE_KEY,
    new ContainerItem({ instance: new UserStepsService({ apiKey: USER_STEPS_API_KEY }) })
  )

  items.set(
    USER_SLEEP_SERVICE_KEY,
    new ContainerItem({ instance: new UserSleepService({ apiKey: USER_SLEEP_API_KEY }) })
  )

  items.set(
    USER_SPO2_SERVICE_KEY,
    new ContainerItem({ instance: new UserSpO2Service({ apiKey: USER_SPO2_API_KEY }) })
  )

  items.set(
    USER_INTENSITY_SERVICE_KEY,
    new ContainerItem({ instance: new UserIntensityService({ apiKey: USER_INTENSITY_API_KEY }) })
  )

  items.set(
    WEARABLE_SERVICE_KEY,
    new ContainerItem({ instance: new WearableService({ apiKey: WEARABLE_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getContentContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[STATISTICS_MODULE],
      },
    })
  }

  return container
}
