import { Box, IconButton, Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import React, { useState } from 'react'
import LineChart from '../../components/lineChart/LineChart'
import StackedBarChart from '../../components/stackedBarChart/StackedBarChart'
import { useTranslation } from 'react-i18next'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import calendarIcon from '../../assets/calendar/calendar-newEvent.svg'
import style from '../calendar/Editor.module.css'
import close from '../../assets/articles/delete.svg'
import dayjs from 'dayjs'

interface StatisticsModalProps {
  hookOpen: boolean
  changeState: () => void
  eventProps: {
    titulo: string
    data: any[]
    dataSetsNames: string[]
    tipoChart: string
  }
}

export type DateFilter = {
  startDate: Date
  finishDate: Date
}

const renderChart = (param: string, props: StatisticsModalProps, date: DateFilter) => {
  switch (param) {
    case 'line':
      return (
        <LineChart
          titulo={props.eventProps.titulo}
          data={props.eventProps.data}
          dataSetsNames={props.eventProps.dataSetsNames}
          filterDate={date}
          eje={true}
          graphicsPoints={7}
        />
      )
    case 'stackedBar':
      return (
        <StackedBarChart
          titulo={props.eventProps.titulo}
          data={props.eventProps.data}
          dataSetsNames={props.eventProps.dataSetsNames}
          filterDate={date}
          eje={true}
        />
      )
  }
}

const StatisticsModal = (props: StatisticsModalProps) => {
  const { t } = useTranslation()
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
  const [isDatePickerFinishOpen, setIsDatePickerFinishOpen] = useState<boolean>(false)

  const [date, setDate] = useState<DateFilter>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
    finishDate: new Date(),
  })
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  const handlerChangeFinishDate = (event: any) => {
    if (!event) return

    setDate(Object.assign({ ...date }, { startDate: date.startDate, finishDate: event.toDate() }))
  }

  const handlerChangeStartDate = (event: any) => {
    if (!event) return
    setDate(Object.assign({ ...date }, { startDate: event.toDate(), finishDate: date.finishDate }))
  }
  const CalendarIcon = () => <img src={calendarIcon} alt="Calendar" />
  return (
    <Modal
      open={props.hookOpen}
      onClose={props.changeState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            borderRadius: '32px',
          }}
        >
          <Box
            sx={{
              width: '50%',
              height: '50%',
              backgroundColor: '#f9f9f9',
              padding: '3%',
              paddingTop: '0px',
              borderRadius: '32px',
            }}
          >
            <IconButton
              sx={{
                marginLeft: '98%',
                marginTop: '3%',
              }}
              onClick={props.changeState}
            >
              <img src={close} alt={'Close icon'} />
            </IconButton>
            <Box style={{ display: 'flex' }}>
              <Box style={{ marginRight: 4 }}>
                <Typography style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                  {t('startDate')}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DatePicker
                    open={isDatePickerOpen}
                    onOpen={() => setIsDatePickerOpen(true)}
                    onClose={() => setIsDatePickerOpen(false)}
                    key={'startDate'}
                    format={'DD/MM/YYYY'}
                    onChange={(e) => handlerChangeStartDate(e)}
                    value={dayjs(date.startDate)}
                    label={''}
                    onError={(reason) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('invalidDateMessage'))
                          break
                        case 'minDate':
                          setDateTimePickerError(t('minDateMessage'))
                          break
                      }
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        id: 'startDate',
                        helperText: DateTimePickerError,
                        InputProps: {
                          sx: {
                            '& fieldset': {
                              borderRadius: 32,
                              border: '1px solid #E8E7EC',
                              fontFamily: 'Montserrat',
                            },
                            '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                            '& .MuiOutlinedInput-root': {
                              border: 'none',
                              '&.Mui-focused fieldset': {
                                borderColor: '#ADB84E',
                              },
                            },
                          },
                          onClick: () => setIsDatePickerOpen(true),
                        },
                      },
                    }}
                    slots={{
                      openPickerIcon: CalendarIcon,
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <Typography style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                  {t('finishDate')}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DatePicker
                    open={isDatePickerFinishOpen}
                    onOpen={() => setIsDatePickerFinishOpen(true)}
                    onClose={() => setIsDatePickerFinishOpen(false)}
                    key={'finishDate'}
                    format={'DD/MM/YYYY'}
                    onChange={(e) => handlerChangeFinishDate(e)}
                    value={dayjs(date.finishDate)}
                    label={''}
                    onError={(reason) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('invalidDateMessage'))
                          break
                        case 'minDate':
                          setDateTimePickerError(t('minDateMessage'))
                          break
                      }
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        id: 'finishDate',
                        helperText: DateTimePickerError,
                        InputProps: {
                          sx: {
                            '& fieldset': {
                              borderRadius: 32,
                              border: '1px solid #E8E7EC',
                              fontFamily: 'Montserrat',
                            },
                            '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                            '& .MuiOutlinedInput-root': {
                              border: 'none',
                              '&.Mui-focused fieldset': {
                                borderColor: '#ADB84E',
                              },
                            },
                          },
                          onClick: () => setIsDatePickerFinishOpen(true),
                        },
                      },
                    }}
                    slots={{
                      openPickerIcon: CalendarIcon,
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            {renderChart(props.eventProps.tipoChart, props, date)}
          </Box>
        </Box>
      </>
    </Modal>
  )
}

export default StatisticsModal
