import i18n from '../../../i18n'

export enum EventCategory {
  Medication = 1,
  Training,
  Landmarks,
  Vaccines,
  Reviews,
  Appointment,
  Forms,
  Advices,
  Others,
  Note,
}

export const eventCategoryTypes = (): Record<EventCategory, string> => ({
  [EventCategory.Medication]: i18n.t('medication'),
  [EventCategory.Training]: i18n.t('training'),
  [EventCategory.Landmarks]: i18n.t('landmarks'),
  [EventCategory.Vaccines]: i18n.t('vaccines'),
  [EventCategory.Reviews]: i18n.t('reviews'),
  [EventCategory.Appointment]: i18n.t('appointment'),
  [EventCategory.Forms]: i18n.t('Froms'),
  [EventCategory.Advices]: i18n.t('advices'),
  [EventCategory.Others]: i18n.t('others'),
  [EventCategory.Note]: i18n.t('note'),
})
