import i18n from '../../../i18n'

export enum LangType {
  Spanish = 1,
  English,
  Italian,
}

export const langTypes = (): Record<LangType, string> => ({
  [LangType.Spanish]: i18n.t('es'),
  [LangType.English]: i18n.t('en'),
  [LangType.Italian]: i18n.t('it'),
})

export const langTypesNumbers = (): Record<string, LangType> => ({
  [i18n.t('es')]: LangType.Spanish,
  [i18n.t('en')]: LangType.English,
  [i18n.t('it')]: LangType.Italian,
})
