import { StatisticsDTO } from './StatisticsDTO'

export class Statistics {
  private _id: string
  private _value: number
  private _userId: string
  private _date: Date

  constructor(p: StatisticsDTO) {
    this._id = p.id
    this._value = p.value
    this._userId = p.userId
    this._date = p.date
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get value(): number {
    return this._value
  }

  set value(value: number) {
    this._value = value
  }

  get userId(): string {
    return this._userId
  }

  set userId(value: string) {
    this._userId = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }
}

export interface StatisticsQuery {
  userId: string
}
