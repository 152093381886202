import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  ARTICLE_API_KEY,
  ARTICLE_SERVICE_KEY,
  CONTENT_MODULE,
  ContentProps,
} from '../modules/content/container'
import { ArticleApi } from '../modules/content/api/ArticleApi'
import { ArticleService } from '../modules/content/services/ArticleService'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(ARTICLE_API_KEY, new ContainerItem({ instance: new ArticleApi() }))

  items.set(
    ARTICLE_SERVICE_KEY,
    new ContainerItem({ instance: new ArticleService({ apiKey: ARTICLE_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getContentContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[CONTENT_MODULE],
      },
    })
  }

  return container
}
