import { TableCell, TableRow } from '@mui/material'
import { User } from 'modules/users/models/User'
import { useTranslation } from 'react-i18next'

type FielValue = {
  date: Date
  values: string[]
}

type ResultsRowProps = {
  users: User[]
  map: Map<string, FielValue[]>
}

export function ResultsRowForm(props: ResultsRowProps) {
  const { t } = useTranslation()

  const renderValues = (value: string) => {
    if (value == 'true') {
      return <TableCell>{t('yes')}</TableCell>
    }
    if (value == 'false') {
      return <TableCell>{t('no')}</TableCell>
    } else {
      return <TableCell>{value.toString()}</TableCell>
    }
  }

  return (
    <>
      {props?.users?.map((a) => {
        return (
          <>
            {props.map?.get(a.id)?.map((u, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell>{a.firstName}</TableCell>
                    <TableCell>{new Date(u.date).toLocaleDateString('es-ES')}</TableCell>
                    {u.values.map((value) => {
                      return (
                        <>
                          <TableCell>{renderValues(value)}</TableCell>
                        </>
                      )
                    })}
                  </TableRow>
                </>
              )
            })}
          </>
        )
      })}
    </>
  )
}
