export function dateToDateString(date: Date): string {
  return date.toLocaleDateString()
}

export function dateToTimeString(date: Date): string {
  return date.toLocaleTimeString()
}

export function dateToTimeWithoutSecondsString(date: Date): string {
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

export function dateToTimeOnlyHoursString(date: Date): string {
  return date.toLocaleTimeString([], { hour: '2-digit' })
}

export function dateToDateTimeString(date: Date): string {
  return date.toLocaleString()
}

export function dateToDateTimeWithoutSecondsString(date: Date): string {
  return date.toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function dateToYYYYMMDD(date: Date): string {
  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  let finalMonth: string
  let finalDay: string

  if (month < 10) {
    finalMonth = `0${month}`
  } else {
    finalMonth = `${month}`
  }

  if (day < 10) {
    finalDay = `0${day}`
  } else {
    finalDay = `${year}-${month}-${day}`
  }

  return `${year}-${finalMonth}-${finalDay}`
}

export function dateToAge(date: string): number {
  const dmy = date.split('/')
  const birthDate = new Date(Number(dmy[2]), Number(dmy[1]) - 1, Number(dmy[0]))
  const today = new Date()
  const age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1
  }
  return age
}

export function getMonday(d: Date) {
  const date = new Date(d)
  let day = date.getDay(),
    diff = date.getDate() - day + (day == 0 ? -6 : 1)
  return new Date(date.setDate(diff))
}
