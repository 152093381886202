import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { RolePermissions as R } from '../../features/role-permissions'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import { useParams } from 'react-router-dom'

export function RolePermissions(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()
  const { id } = useParams()

  setTitle(title)

  return (
    <>
      <R id={id || ''} />
    </>
  )
}
