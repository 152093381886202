import { Container, IInit } from '../../../common/container/Container'
import { AlgorithmExerciseContainerConfig } from '../container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface IExerciseAlgorithmApi extends IInit {
  generateExercises(id: string, startDate: Date, finishDate: Date): void
}

export class ExerciseAlgorithmApi implements IExerciseAlgorithmApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as AlgorithmExerciseContainerConfig).moduleFullUrl
  }

  generateExercises(userId: string, startDate: Date, finishDate: Date): void {
    this._httpClient.post<void>({
      url: `${this._url}/${userId}`,
      body: {
        startDate: startDate,
        finishDate: finishDate,
      },
    })
  }
}
