import React from 'react'
import { ViewProps } from './types'

export function MonthView(props: ViewProps) {
  return (
    <>
      <div>MonthView</div>
    </>
  )
}
