import { Container, IInit } from '../../../common/container/Container'
import { ContentContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Statistics } from '../models/Statistics'
import { toModel, StatisticsDTO } from '../models/StatisticsDTO'

export interface IUserSpO2Api extends IInit {
  getByID(id: string): Observable<Statistics | undefined>

  getFilteredList(q: Query<Statistics>): Observable<ItemList<Statistics>>

  add(e: StatisticsDTO): Observable<Statistics | undefined>

  update(e: StatisticsDTO): Observable<Statistics | undefined>

  delete(id: string): Observable<boolean>
}

export class UserSpO2Api implements IUserSpO2Api {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ContentContainerConfig).moduleFullUrl + '/userSpO2'
  }

  add(e: StatisticsDTO): Observable<Statistics | undefined> {
    return this._httpClient
      .post<Statistics>({ url: this._url, body: e })
      .pipe(
        map<StatisticsDTO, Statistics>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Statistics | undefined> {
    return this._httpClient
      .get<Statistics>({ url: `${this._url}/${id}` })
      .pipe(
        map<StatisticsDTO, Statistics>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredList(q: Query<Statistics>): Observable<ItemList<Statistics>> {
    let url = this._url
    return this._httpClient
      .get<ItemList<Statistics>>({ url: prepareURL(url, q) })
      .pipe(
        map<ItemList<StatisticsDTO>, ItemList<Statistics>>((dto) => {
          const itemList = emptyList<Statistics>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Statistics>())
        })
      )
  }

  update(e: StatisticsDTO): Observable<Statistics | undefined> {
    return this._httpClient
      .put<Statistics>({ url: this._url, body: e })
      .pipe(
        map<StatisticsDTO, Statistics>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
