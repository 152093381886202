export const PERMISSIONS: string[] = [
  'Vincular usuario al círculo del paciente',
  'Ver y descargar lista de círculos',
  'Visualizar estadísticas de pacientes',
  'Configurar y recibir alertas / notificaciones',
  'Mensajería y compartir archivos',
  'Configurar calendario de paciente',
  'Visualizar calendario del paciente',
  'Agendar consulta',
  'Registrar datos del paciente',
  'Registrar informes de especialista',
  'Registrar síntomas y crisis',
  'Diseñar y enviar formularios',
  'Llenar formularios o encuestas',
  'Crear y publicar contenido',
  'Visualizar muro noticias y biblioteca de contenidos',
  'Registrar tratamiento farmacológico',
  'Visualizar gráficos de control evolutivo',
  'Visualizar calendario vacunal',
  'Configurar listado salud Bucodental',
  'Visualizar listado salud Bucodental',
  'Subir y compartir recursos',
  'Conectar con dispositivo wearable',
  'Visualizar datos de dispositivos wearables',
  'Visualizar y editar los permisos asociados a cada rol',
  'Subir a Ágora',
  'Ver detalles, editar y eliminar hitos de desarrollo',
  'Ver detalles, editar y eliminar seguimientos del crecimiento',
  'Crear seguimientos del crecimiento',
  'Visualizar los datos del paciente',
  'Visualizar formularios del paciente',
  'Crear datos familiares de interés de un paciente',
  'Visualizar formularios',
  'Ver detalles, editar y eliminar formularios',
  'Visualizar, editar y crear vacunas',
]
