import { Container } from '../../common/container/Container'

export type ContentContainerConfig = {
  moduleFullUrl: string
}

export type ContentProps = {
  parentContainer: Container
  config: ContentContainerConfig
}

export const CONTENT_MODULE = Symbol('CONTENT_MODULE')

export const ARTICLE_API_KEY = Symbol('ARTICLE_API_KEY')

export const ARTICLE_SERVICE_KEY = Symbol('ARTICLE_SERVICE_KEY')
