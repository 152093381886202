import { Box, List } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './Navigation.module.css'
import { Link } from 'react-router-dom'
import {
  ROUTE_ARTICLES,
  ROUTE_CALENDAR,
  ROUTE_CIRCLES,
  ROUTE_MESSENGER,
  ROUTE_PATIENTS,
  ROUTE_RESOURCES,
  ROUTE_STATISTICS,
} from '../../routes/routes-constants'
import circleIcon from '../../assets/left_menu/circulos.svg'
import circleActive from '../../assets/left_menu/circulos-act.svg'
import circleIconHover from '../../assets/left_menu/circulos-hover.svg'
import circleManageIcon from '../../assets/left_menu/circulos-admin.svg'
import circleManageActive from '../../assets/left_menu/circulos-admin-act.svg'
import circleManageIconHover from '../../assets/left_menu/circulos-admin-hover.svg'
import notifications from '../../assets/left_menu/notificaciones.svg'
import notificationsActive from '../../assets/left_menu/notificaciones-act.svg'
import notificationsHover from '../../assets/left_menu/notificaciones-hover.svg'
import patientData from '../../assets/left_menu/datos-paciente.svg'
import patientDataActive from '../../assets/left_menu/datos-paciente-act.svg'
import patientDataHover from '../../assets/left_menu/datos-paciente-hover.svg'
import chat from '../../assets/left_menu/chat.svg'
import chatActive from '../../assets/left_menu/chat-act.svg'
import chatHover from '../../assets/left_menu/chat-hover.svg'
import calendar from '../../assets/left_menu/calendar.svg'
import calendarActive from '../../assets/left_menu/calendar-act.svg'
import calendarHover from '../../assets/left_menu/calendar-hover.svg'
import resources from '../../assets/left_menu/recursos.svg'
import resourcesActive from '../../assets/left_menu/recursos-act.svg'
import resourcesHover from '../../assets/left_menu/recursos-hover.svg'
import { ICircleService } from '../../modules/users/services/CircleService'
import { User } from '../../modules/users/models/User'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getUserContainer } from '../../container/user-module'
import { CIRCLE_SERVICE_KEY } from '../../modules/users/container'

type LinkType = {
  route: string
  title: string
  activeIcon: string
  icon: string
  altSrc: string
  hoverIcon: string
  isVisible: boolean
}

type MainNavigationProps = {
  selectedMenu: string
}

const circleService = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export function Navigation(props: MainNavigationProps) {
  const { t } = useTranslation()

  const [circle, setCircle] = useState<User | undefined>(circleService.getActive())
  const [selectedMenu, setSelectedMenu] = useState<string>(props.selectedMenu)

  const links: LinkType[] = [
    {
      route: ROUTE_CIRCLES,
      title: 'start',
      activeIcon: circleActive,
      icon: circleIcon,
      altSrc: 'circleOfPatientIcon',
      hoverIcon: circleIconHover,
      isVisible: true,
    },
    {
      route: ROUTE_PATIENTS,
      title: 'patients',
      activeIcon: notificationsActive,
      icon: notifications,
      altSrc: 'notificationsIcon',
      hoverIcon: notificationsHover,
      isVisible: true,
    },
    {
      route: ROUTE_MESSENGER,
      title: 'queries',
      activeIcon: circleManageActive,
      icon: circleManageIcon,
      altSrc: 'manageCircleIcon',
      hoverIcon: circleManageIconHover,
      isVisible: true,
    },
    {
      route: ROUTE_ARTICLES,
      title: 'news',
      activeIcon: patientDataActive,
      icon: patientData,
      altSrc: 'patientDataIcon',
      hoverIcon: patientDataHover,
      isVisible: true,
    },
    {
      route: ROUTE_RESOURCES,
      title: 'resources',
      activeIcon: chatActive,
      icon: chat,
      altSrc: 'calendarIcon',
      hoverIcon: chatHover,
      isVisible: true,
    },
    {
      route: ROUTE_CALENDAR,
      title: 'surveys',
      activeIcon: calendarActive,
      icon: calendar,
      altSrc: 'calendarIcon',
      hoverIcon: calendarHover,
      isVisible: true,
    },
    {
      route: ROUTE_STATISTICS,
      title: 'statistics',
      activeIcon: resourcesActive,
      icon: resources,
      altSrc: 'resourcesIcon',
      hoverIcon: resourcesHover,
      isVisible: true,
    },
  ]

  const drawer = (
    <div className={style.listContainer}>
      <List>
        {links.map(
          (l) =>
            l.isVisible && (
              <Link
                key={l.title}
                to={l.route}
                className={getLinkStyle(selectedMenu, l.route)}
                id={l.route}
              >
                <ListItem
                  title={t(l.title)}
                  iconSrc={selectedMenu === l.route ? l.activeIcon : l.icon}
                  altSrc={t(l.altSrc)}
                  hoverIconSrc={l.hoverIcon}
                />
              </Link>
            )
        )}
      </List>
    </div>
  )

  useEffect(() => {
    setSelectedMenu(props.selectedMenu)
  }, [props.selectedMenu])

  useEffect(() => {
    circleService.getActiveObservable().subscribe((c) => setCircle(c))
  }, [circle])

  return <aside className={style.aside}>{useMediaQuery('(min-width:900px)') && drawer}</aside>
}

type ListItemProps = {
  title: string
  iconSrc: string
  altSrc: string
  isDisabled?: boolean
  hoverIconSrc: string
}

function ListItem(props: ListItemProps): JSX.Element {
  return (
    <div className={style.menuDiv}>
      <Box key={props.title} className={style.menuList}>
        <Box
          className={getListItemStyle(props.isDisabled || false)}
          display="flex"
          alignItems="center"
        >
          <img className={style.menuIcon} src={props.iconSrc} alt={props.altSrc} />
          <h3 className={style.titleName}>{props.title}</h3>
        </Box>
      </Box>
    </div>
  )
}

const getLinkStyle = (menuSelected: string, ...ids: string[]): string =>
  `${style.link} ${ids.includes(menuSelected) ? style.linkActive : style.linkInactive}`

const getListItemStyle = (isDisabled: boolean): string =>
  `${isDisabled ? style.menuItemDisabled : style.menuItem}`
