import React, { useEffect } from 'react'
import { AppRouter } from '../../routes/AppRouter'

import style from './Main.module.css'

type MainProps = {
  selectedMenu: string
}

export function Main(props: MainProps) {
  const mainContainer = React.useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (mainContainer.current !== null) {
      mainContainer.current.scrollTop = 0
    }
  }, [props.selectedMenu])

  return (
    <div ref={mainContainer} className={style.mainContainer} id="mainContainer">
      <AppRouter />
    </div>
  )
}
